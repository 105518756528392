export const featureAccess = {
    showTechnicalProblemMessage: false,
    showWebAppBtn: true, // show or not button webapp on mathia toolbar
    institutionsGroupAccess: false,
    translationAccess: {
        show: false,
        withFlag: true
    },
    feedbackMenu: true // menu to send feedback
};
