export const activities = {
    accessMatrix: {
        activitiesListing: {
            view: ['learner', 'trainer', 'manager', 'administrator'],
        },
        lessonsListing: {
            view: ['trainer', 'manager', 'administrator'],
        }
    },
    imageFullscreenButton: false,
    actionButtonsInMultiMatrix: {
        default: ['previousActivityInMulti', 'nextActivityInMulti']
    },
    activitiesDisplayedColumns: ['title', 'educationalLevel', 'chapters', 'action'],
    activitiesWithDefinedTypology: ['EXT'], // type of activities used for filter in editor-activities-list
    allowErrorReporting: false,
    activateNewEditor: true,
    assignableLesson: true,
    autoCorrection: true,
    cardFieldsForPreview: ['chapters', 'description'],
    cardLayout: 'card-split-media-info',
    displayFeedback: false, // display feedback for each activities played
    displayFullscreenButton : false,
    closeButtonPng: true,
    displayFilters: false,
    enableTTS: true, // add TTS option for wording & help
    initLoadLessonFilter: ['author'],
    isModalConfirmBeforeCloseExo: true,
    showHelp: true,
    showActivityTitle: false,
    showActivityQuestionIndex: true,
    showAddLessonButtonCard: true,
    showProgressBarExo: true, // show a progress bar in an exo
    hideAddButtonLessonList: true,
    hideUserActionButtons: true,
    hiddenActivityPreview: false,
    isArrowNavigationEnabled: {  // allow arrow for navigation in lesson (player)
        learner: false,
        default: true
    },
    isOnlyModelLesson: true, // should be called by example : enableSelectionByTab for lesson list component
    latexKeyboard: true,
    lessonStep: null,
    loadLessonWithSublesson: {
        typology: null,
        multi_step: 0
    },
    openDialogInfoAfterDuplicateLesson: {
        manager: false,
        default: true
    },
    searchFields: ['title', 'educationnalLevel', 'assignation_type', 'launchSearch', 'countEntities'],
    showLessonTitle: false,
    stepper: null,
    allowedRolesForModelsAssignation: ['manager', 'trainer'],
    allowedRolesForAutoAssignmentCreation: ['learner', 'trainer', 'manager', 'administrator'],
    allowedActivityTypes: [],
    // required params name for partner through iframe url
    iframeParams: ['uid', 'prenom', 'codeclasse', 'token', 'assignationId'],
    dynamicContent: false,
    menu: {
        models: false
    },
    filtersEditorActivitiesList: ['title', 'educationnalLevel', 'chapters'],
    lessonDialogFields: {
        default: ['title', 'description', 'educationalLevel', 'assignation_type'],
    },
    lessonDialogRequiredFields: {
        default: ['title', 'educationalLevel', 'assignation_type'],
    },
    loadSubActivitiesOfAllSubLesson: false, // load activities from each sub-lesson in lesson at the initialise of the lesson to be played.
    saveLessonContentOptions: {
        activityTypesCanBeDuplicate: [], // type d'activitiés que l'on peut dupliquer (certaines activitiés n'ont pas besoin d'être dupliqué)
        saveContent: false // si chaque activités ou souslesson du parcours doit être sauvegardé
    },
    showSubInstruction: true,
    typeActivitiesToSkip: ['EXT'], // (lesson player) skip activity if activity's type is included,
    useRewardPageInsteadOfRecap: true,
    displayLearnerInfo: false,
    recommendationDisplayedColumns: ['checkbox', 'pseudo', 'lesson', 'level', 'type', 'confidence', 'buttons'],
    shouldSetDefaultOptionsOnActivityList: true,
    setAnswerWithUserSave: false, // à l'initialisation d'une activité, inscrit les réponses presente dans l'user-save
    activitiesBroadcastLifeCycle: true,  // envoi les événements du cycle de vie au module LRS Module via le communication service,
    forceUrlByDefaultWhenBackToLessonList: true, // force par defaut l'utilisation d'une url predefinis pour le retour à l'ecran de listing des parcours
    symbolsForLatexKeyboard: {
        alphabetic: {
            options: {
                blacklistTabs: [],
                focusByDefaultDisplayed: true, /* focus first field to fill by default (no need to click first) */
                keepKeyboardDisplayed: true, /* keep keyboard displayed even if user click outside keyboard range */
                validateButtonClass: 'validate', /* class css of validate's button */
                validateButtonText: 'Validate', /* text of validate's button (it have one by default) */
                validateButton: true, /* enable of not validate's button */
                moveLeftButton: false,                               /* enable of not moveLeft's button */
                moveRightButton: false,                               /* enable of not moveRight's button */
                backspaceButton: true,                               /* enable of not backspace's button */
                hideKeyboardButton: false,                               /* enable of not hideKeyboard's button */
                toggleTabButton: true,                               /* enable of not toggleTab's button */
                toggleTabButtonOptions: { // options for button toggle in keyboard
                    displayUnique: true // one tab can be used to switch between all keyboards (ABC, abc, 123, ...)
                }
            },
            data: {
                tabs: [
                    {
                        "name": "abc",
                        "label": "abc",
                        "columns": 11,
                        "rows": 4,
                        "classes": ['alphabetic'],
                        "symbols": [
                            { "button": "q",  "latex": "q", "displayed": "q"},
                            { "button": "w", "latex": "w", "displayed": "w"},
                            { "button": "e", "latex": "e", "displayed": "e"},
                            { "button": "r", "latex": "r", "displayed": "r"},
                            { "button": "t", "latex": "t", "displayed": "t"},
                            { "button": "z", "latex": "z", "displayed": "z"},
                            { "button": "u", "latex": "u", "displayed": "u"},
                            { "button": "i", "latex": "i", "displayed": "i"},
                            { "button": "o", "latex": "o", "displayed": "o"},
                            { "button": "p", "latex": "p", "displayed": "p"},
                            { "button": "ü", "latex": "ü", "displayed": "ü"},

                            { "button": "a", "latex": "a", "displayed": "a"},
                            { "button": "s",  "latex": "s", "displayed": "s"},
                            { "button": "d",  "latex": "d", "displayed": "d"},
                            { "button": "f",  "latex": "f", "displayed": "f"},
                            { "button": "g",  "latex": "g", "displayed": "g"},
                            { "button": "h",  "latex": "h", "displayed": "h"},
                            { "button": "j",  "latex": "j", "displayed": "j"},
                            { "button": "k",  "latex": "k", "displayed": "k"},
                            { "button": "l",  "latex": "l", "displayed": "l"},
                            { "button": "ö",   "latex": "ö", "displayed": "ö"},
                            { "button": "ä",   "latex": "ä", "displayed": "ä"},

                            { "button": "ß",   "latex": "ß",    "displayed": "ß"},
                            { "button": "y", "latex": "y", "displayed": "y"},
                            { "button": "x", "latex": "x", "displayed": "x"},
                            { "button": "c", "latex": "c", "displayed": "c"},
                            { "button": "v", "latex": "v", "displayed": "v"},
                            { "button": "b", "latex": "b", "displayed": "b"},
                            { "button": "n", "latex": "n", "displayed": "n"},
                            { "button": "m",  "latex": "m", "displayed": "m"},

                            { "button": ".",   "latex": ".",    "displayed": "."},
                            { "button": ",", "latex": ",", "displayed": ","},
                            { "button": "!",   "latex": "!",    "displayed": "!"},
                            { "button": "?",   "latex": "?",    "displayed": "?"},

                            { "button": "⎵",   "latex": " ",    "displayed": "\\hspace{5pt}", "classes": ["spacebar"]}
                        ]
                    },
                    {
                        "name": "ABC",
                        "label": "ABC",
                        "columns": 11,
                        "rows": 4,
                        "classes": ['alphabetic-maj'],
                        "symbols": [
                            { "button": "Q", "latex": "Q", "displayed": "Q"},
                            { "button": "W", "latex": "W", "displayed": "W"},
                            { "button": "E", "latex": "E", "displayed": "E"},
                            { "button": "R", "latex": "R", "displayed": "R"},
                            { "button": "T", "latex": "T", "displayed": "T"},
                            { "button": "Z", "latex": "Z", "displayed": "Z"},
                            { "button": "U", "latex": "U", "displayed": "U"},
                            { "button": "I", "latex": "I", "displayed": "I"},
                            { "button": "O", "latex": "O", "displayed": "O"},
                            { "button": "P", "latex": "P", "displayed": "P"},
                            { "button": "Ü", "latex": "Ü", "displayed": "Ü"},

                            { "button": "A", "latex": "A", "displayed": "A"},
                            { "button": "S", "latex": "S", "displayed": "S"},
                            { "button": "D", "latex": "D", "displayed": "D"},
                            { "button": "F", "latex": "F", "displayed": "F"},
                            { "button": "G", "latex": "G", "displayed": "G"},
                            { "button": "H", "latex": "H", "displayed": "H"},
                            { "button": "J", "latex": "J", "displayed": "J"},
                            { "button": "K", "latex": "K", "displayed": "K"},
                            { "button": "L", "latex": "L", "displayed": "L"},
                            { "button": "Ö", "latex": "Ö", "displayed": "Ö"},
                            { "button": "Ä", "latex": "Ä", "displayed": "Ä"},

                            { "button": "ẞ", "latex": "ẞ", "displayed": "ẞ"},
                            { "button": "Y", "latex": "Y", "displayed": "Y"},
                            { "button": "X", "latex": "X", "displayed": "X"},
                            { "button": "C", "latex": "C", "displayed": "C"},
                            { "button": "V", "latex": "V", "displayed": "V"},
                            { "button": "B", "latex": "B", "displayed": "B"},
                            { "button": "N", "latex": "N", "displayed": "N"},
                            { "button": "M", "latex": "M", "displayed": "M"},

                            { "button": ".", "latex": ".", "displayed": "."},
                            { "button": ",", "latex": ",", "displayed": ","},
                            { "button": "!",   "latex": "!",    "displayed": "!"},
                            { "button": "?",   "latex": "?",    "displayed": "?"},

                            { "button": "⎵",   "latex": " ",    "displayed": "\\hspace{5pt}", "classes": ["spacebar"]}
                        ]
                    }
                ]
            }
        },
        alphabeticUppercase: {
            options: {
                blacklistTabs: [],
                focusByDefaultDisplayed: true, /* focus first field to fill by default (no need to click first) */
                keepKeyboardDisplayed: true, /* keep keyboard displayed even if user click outside keyboard range */
                validateButtonClass: 'validate', /* class css of validate's button */
                validateButtonText: 'Validate', /* text of validate's button (it have one by default) */
                validateButton: true, /* enable of not validate's button */
                moveLeftButton: false,                               /* enable of not moveLeft's button */
                moveRightButton: false,                               /* enable of not moveRight's button */
                backspaceButton: true,                               /* enable of not backspace's button */
                hideKeyboardButton: false,                               /* enable of not hideKeyboard's button */
                toggleTabButton: false,                               /* enable of not toggleTab's button */
                toggleTabButtonOptions: { // options for button toggle in keyboard
                    displayUnique: false // one tab can be used to switch between all keyboards (ABC, abc, 123, ...)
                }
            },
            data: {
                tabs: [
                    {
                        "name": "ABC",
                        "label": "ABC",
                        "columns": 11,
                        "rows": 4,
                        "classes": ['alphabeticUppercase'],
                        "symbols": [
                            { "button": "Q", "latex": "Q", "displayed": "Q"},
                            { "button": "W", "latex": "W", "displayed": "W"},
                            { "button": "E", "latex": "E", "displayed": "E"},
                            { "button": "R", "latex": "R", "displayed": "R"},
                            { "button": "T", "latex": "T", "displayed": "T"},
                            { "button": "Z", "latex": "Z", "displayed": "Z"},
                            { "button": "U", "latex": "U", "displayed": "U"},
                            { "button": "I", "latex": "I", "displayed": "I"},
                            { "button": "O", "latex": "O", "displayed": "O"},
                            { "button": "P", "latex": "P", "displayed": "P"},
                            { "button": "Ü", "latex": "Ü", "displayed": "Ü"},

                            { "button": "A", "latex": "A", "displayed": "A"},
                            { "button": "S", "latex": "S", "displayed": "S"},
                            { "button": "D", "latex": "D", "displayed": "D"},
                            { "button": "F", "latex": "F", "displayed": "F"},
                            { "button": "G", "latex": "G", "displayed": "G"},
                            { "button": "H", "latex": "H", "displayed": "H"},
                            { "button": "J", "latex": "J", "displayed": "J"},
                            { "button": "K", "latex": "K", "displayed": "K"},
                            { "button": "L", "latex": "L", "displayed": "L"},
                            { "button": "Ö", "latex": "Ö", "displayed": "Ö"},
                            { "button": "Ä", "latex": "Ä", "displayed": "Ä"},

                            { "button": "Y", "latex": "Y", "displayed": "Y"},
                            { "button": "X", "latex": "X", "displayed": "X"},
                            { "button": "C", "latex": "C", "displayed": "C"},
                            { "button": "V", "latex": "V", "displayed": "V"},
                            { "button": "B", "latex": "B", "displayed": "B"},
                            { "button": "N", "latex": "N", "displayed": "N"},
                            { "button": "M", "latex": "M", "displayed": "M"},

                            { "button": ".", "latex": ".", "displayed": "."},
                            { "button": "!",   "latex": "!",    "displayed": "!"},
                            { "button": "?",   "latex": "?",    "displayed": "?"},
                            { "button": "ẞ", "latex": "ẞ", "displayed": "ẞ"},
                            { "button": "\"",   "latex": "\"",    "displayed": "\""},
                        ]
                    }
                ]
            }
        },
        numeric: {
            options: {
                blacklistTabs: [],
                focusByDefaultDisplayed: true, /* focus first field to fill by default (no need to click first) */
                keepKeyboardDisplayed: true, /* keep keyboard displayed even if user click outside keyboard range */
                validateButtonClass: 'validate', /* class css of validate's button */
                validateButtonText: 'Validate', /* text of validate's button (it have one by default) */
                validateButton: true, /* enable of not validate's button */
                moveLeftButton: false,                               /* enable of not moveLeft's button */
                moveRightButton: false,                               /* enable of not moveRight's button */
                backspaceButton: true,                               /* enable of not backspace's button */
                hideKeyboardButton: false,                               /* enable of not hideKeyboard's button */
                toggleTabButton: false,                               /* enable of not toggleTab's button */
            },
            data: {
                tabs: [
                    {
                        "name": "123 primaire",
                        "label": "123",
                        "columns": 6,
                        "rows": 4,
                        "classes": ["primaire", "numeric"],
                        "symbols": [
                            { "button": "(", "latex": "(", "displayed": "("},
                            { "button": "1", "latex": "1", "displayed": "1", "classes": ["active"]},
                            { "button": "2", "latex": "2", "displayed": "2", "classes": ["active"]},
                            { "button": "3", "latex": "3", "displayed": "3", "classes": ["active"]},
                            { "button": "+", "latex": "+", "displayed": "+"},
                            { "button": ".", "latex": ".", "displayed": "."},
                            { "button": ")", "latex": ")", "displayed": ")"},
                            { "button": "4", "latex": "4", "displayed": "4", "classes": ["active"]},
                            { "button": "5", "latex": "5", "displayed": "5", "classes": ["active"]},
                            { "button": "6", "latex": "6", "displayed": "6", "classes": ["active"]},
                            { "button": "-", "latex": "-", "displayed": "-"},
                            { "button": ":", "latex": ":", "displayed": ":"},
                            { "button": "<", "latex": "<", "displayed": "<"},
                            { "button": "7", "latex": "7", "displayed": "7", "classes": ["active"]},
                            { "button": "8", "latex": "8", "displayed": "8", "classes": ["active"]},
                            { "button": "9", "latex": "9", "displayed": "9", "classes": ["active"]},
                            { "button": ">", "latex": ">", "displayed": ">"},
                            { "button": "=", "latex": "=", "displayed": "="},
                            { "button": "0", "latex": "0", "displayed": "0", "classes": ["active"]},
                            { "button": ",", "latex": ",", "displayed": ","},
                        ]
                    }
                ]
            },
        }
    },
    buttons:     {
        player : {
            training: [
                {
                    display: false,
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option'],
                    options: {
                        MULTI: {
                            display: {
                                case : 'displayWithDelay',
                                value: 2000
                            }
                        }
                    }
                },
            ],
            auto: [
                {
                    display: false,
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option'],
                    options: {
                        MULTI: {
                            display: {
                                case : 'displayWithDelay',
                                value: 2000
                            }
                        }
                    }
                },
            ],
        },
    },
};
