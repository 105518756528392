import {lrs} from './modules/lrs';
import {environment, getFrontType, getTrackingID, getTrackingIDMatomo} from '../../../environments/environment';
import {activities} from './modules/activities';
import {accountManagement} from './modules/account-management';
import {authentication} from './modules/authentication';
import {corpus} from './modules/corpus';
import {assignation} from './modules/assignation';
import {dashboard} from './modules/dashboard';
import {cards} from './core/cards';
import {groupsManagement} from './modules/groups-management';
import {mainMenu} from './core/menu';
import {graphAssignation} from './modules/graph-assignation';
import {gamification} from './modules/gamification';
import {licensing} from './modules/licensing';
import {featureAccess} from './core/feature-access';
import {researchSheet} from './modules/research-sheet';
import {notification} from './modules/notification';
import {projectsManagement} from './modules/projects-management';
import {ideasWall} from './modules/ideas-wall';
import {browserTest} from './modules/browser-test';
import {configuration} from './modules/configuration';
import {timeline} from './modules/timeline';
import {homePage} from './modules/home-page';
import {basicPage} from './modules/basic-page';
import {header} from './core/header';

export const frontType = getFrontType(); // define which front is making backend calls

export const brand = 'ubolino_app'; // instance name of repository project, asset floder name, settings ...
export const baseAppUrl = environment.baseAppUrl(brand);
export const brandTitle = 'Ubolino'; // index.html title tab title
export const captcha = 'friendlyCaptcha'; // 'recaptcha' (google) or 'hcaptcha' or 'friendlyCaptcha'. Hcaptcha not working on localhost, use test.mydomain.com instead => (ng serve --host test.mydomain.com)
export const GATracking = false; // allow or not Google Analytics tracking
export const matomoTracking = true; // allow or not matomo tracking
export const matomoTrackingID = '33'; // à changer, ID matomo de la prod de l'instance
export const matomoTrackingIDFromSettings = getTrackingIDMatomo(matomoTrackingID);
export const trackingID = '';
export const trackingIDFromSettings = getTrackingID(trackingID);
export const LRSTracking = false; // allow or not LRS tracking
export const LRSTrackingUrl = '';
export const brandLogoSvg = true;
export const defaultApiURL = environment.baseApiUrl(brand);
export const defaultNodeURL = environment.baseNodeUrl(brand);
export const defaultURL = environment.baseUrl(brand);
export const defaultLoginRoute = '/lessons/home';
export const defaultRoute = '/connect';
export const defaultLanguage = 'de';
export const filterByCurrentYearByDefault = false;
export const langs = [
    {
        id: 'fr',
        title: 'Français',
        flag: 'fr',
        code: 'fr-FR'
    },
    {
        id: 'de',
        title: 'Deutsch',
        flag: 'de',
        code: 'de-DE'
    }
];
export const dateTimeOptions: Intl.DateTimeFormatOptions = {weekday: undefined, year: 'numeric', month: 'numeric', day: 'numeric'};
export const tralalereBar = {
    displayed: false
};
export const defaultValue = {
};
export const displayHelp = false;

export const displayHeader = false;

export const displayHeaderTitle = true;

export const customHomePageBanner = false;

export const titleTextInHomePageBanner = false;

export const cardLayout = 'card-split-media-info';

export const onboarding: { [role: string]: string[] } = {
    default: [],
    trainer: ['upsell']
};

export const globalNewsSettings = {};
export const modulesSettings = {
    accountManagement,
    activities,
    assignation,
    authentication,
    basicPage,
    browserTest,
    cards,
    corpus,
    dashboard,
    featureAccess,
    gamification,
    graphAssignation,
    groupsManagement,
    homePage,
    ideasWall,
    licensing,
    mainMenu,
    notification,
    projectsManagement,
    researchSheet,
    configuration,
    timeline,
    header,
    lrs
};

// https://tarteaucitron.io/fr/
export const tarteaucitronConf: object = {
    'privacyUrl': '', /* Privacy policy url */
    'hashtag': '#tarteaucitron', /* Open the panel with this hashtag */
    'cookieName': 'tarteaucitron', /* Cookie name */
    'orientation': 'popup', /* Banner position (top - bottom - middle - popup) */
    'groupServices': false, /* Group services by category */
    'showAlertSmall': false, /* Show the small banner on bottom right */
    'cookieslist': true, /* Show the cookie list */
    'showIcon': true, /* Show cookie icon to manage cookies */
    'iconSrc': 'https://www.tralalere.com/header/images/cookies.svg', /* Optionnal: URL or base64 encoded image */
    'iconPosition': 'BottomLeft', /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */
    'adblocker': false, /* Show a Warning if an adblocker is detected */
    'DenyAllCta': true, /* Show the deny all button */
    'AcceptAllCta': true, /* Show the accept all button when highPrivacy on */
    'highPrivacy': true, /* HIGHLY RECOMMANDED Disable auto consent */
    'handleBrowserDNTRequest': false, /* If Do Not Track == 1, disallow all */
    'removeCredit': true, /* Remove credit link */
    'moreInfoLink': false, /* Show more info link */
    'useExternalCss': false, /* If false, the tarteaucitron.css file will be loaded */
    // 'cookieDomain': '.my-multisite-domaine.fr', /* Shared cookie for subdomain website */
    'readmoreLink': 'https://www.tralalere.com/page/rgpd', /* Change the default readmore link pointing to tarteaucitron.io */
    'mandatory': false, /* Show a message about mandatory cookies */
    'bodyPosition': 'bottom',
    'closePopup': false
};

// export * from './core/fuse-config';
