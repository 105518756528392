import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective} from '@angular/forms';
import {brand} from '../../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, AfterViewInit, OnDestroy {
    isMessageSend = false;
    messageSendError = false;
    feedbackForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        category: new UntypedFormControl('', [Validators.required]),
        content: new UntypedFormControl('', [Validators.required]),
    });
    public categoryList: { id: string | number, label: string, content: string }[] = [];
    public assetbrand = brand;

    constructor(private authenticationService: AuthenticationService,
                private octopusConnectService: OctopusConnectService,
                private router: Router,
                private translateService: TranslateService) {

        this.octopusConnectService.loadCollection('feedback').subscribe(category => {
            category.entities.forEach((cat: DataEntity) => {
                this.categoryList.push({
                    id: cat.id,
                    label: cat.get('label'),
                    content: cat.get('description')
                });
            });
        });
    }

    ngOnInit(): void {
        this.setUserMailToContactIfExist();
    }

    ngAfterViewInit(): void {
    }

    /**
     * if user connected has an email push this email on the field email
     */
    private setUserMailToContactIfExist(): void {
        if (this.authenticationService.userData && this.authenticationService.userData.get('email')) {
            this.feedbackForm.patchValue({email: this.authenticationService.userData.get('email')});
        }
    }

    /**
     * listen category list change to show or not other fields
     * @param category
     */
    changeCategory(category: any): void {
        this.translateService.get(this.categoryList.filter(c => c.id === category.value)[0].content)
            .subscribe((translation: string) => {
                this.feedbackForm.patchValue({'content': translation});
            });
    }

    onSubmit(): void {
        this.sendData();
        this.resetForm();
        this.setUserMailToContactIfExist();
    }

    /**
     * reset form after sending message
     */
    private resetForm(): void {
        this.feedbackForm.reset();
        this.feedbackForm.controls.email.setErrors(null);
        this.feedbackForm.controls.content.setErrors(null);
        this.feedbackForm.controls.category.setErrors(null);
    }

    /**
     * send data to backend
     */
    private sendData(): void {
        const feedback = {
            email: this.feedbackForm.value.email,
            category: this.feedbackForm.value.category,
            content: this.feedbackForm.value.content
        };

        // todo mettre dans le subscribe quand il marchera
        this.isMessageSend = true;
        setTimeout(() => {
            this.isMessageSend = false;
            this.router.navigate(['../']);
        }, 3000);


        this.octopusConnectService.createEntity('form-project', feedback)
            .subscribe((res: DataEntity) => {
                this.isMessageSend = true;
                setTimeout(() => {
                    this.isMessageSend = false;
                    this.router.navigate(['../']);
                }, 3000);
            }, err => {
                console.log(err);
                this.messageSendError = true;
                setTimeout(() => {
                    this.isMessageSend = false;
                }, 2000);
            });
    }

    ngOnDestroy(): void {
    }
}
