import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { AuthenticationService } from "../authentication.service";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class CanAccessToAuthenticationFeaturesGuard  {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const canAccessToAuthenticationFeatures =
      this.authenticationService.settings.canAccessToAuthenticationFeatures;

    if (canAccessToAuthenticationFeatures) return true;

    this.router.navigate(["connect-via-gar"]);
  }
}
