import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {brand, brandLogoSvg} from '../../../../settings';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {QuickConnectUserType} from '@modules/authentication/core/models/quick-connect-user.type';
import {Router} from '@angular/router';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-quick-connect-page',
    templateUrl: './quick-connect-page.component.html'
})

export class QuickConnectPageComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() localUsers = [];

    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public isAuthenticated = false;
    public currentUser: DataEntity;
    public showSpinner = false;

    constructor(
        private translate: TranslateService,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                    this.isAuthenticated = true;
                } else {
                    this.postLogout();
                }
            });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (this.isAuthenticated) {
            this.communicationCenter
                .getRoom('skeleton')
                .next('addClass', 'position-top-lower-for-content');
        }
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'position-top-lower-for-content');
    }

    public login(localUser: QuickConnectUserType): void {

        let loginValue: string;
        let passwordValue: string;

        if (localUser.role.includes(6)) { // if user has learner role
            loginValue = localUser.codeid + '@nopasswordregistration.tralalere.com';
            passwordValue = localUser.codeid;
        } else {
            loginValue = 'tralacodeid' + localUser.label;
            passwordValue = localUser.codeid;
        }

        this.showSpinner = true;
        this.authenticationService.login(loginValue, passwordValue);

    }

    public delete(label: string): void {

        const data = {
            titleDialog: 'quick-connect.remove-account-title',
            bodyDialog: 'quick-connect.remove-account-body',
            labelTrueDialog: 'generic.remove',
            labelFalseDialog: 'generic.cancel',
        };

        if (label === this.currentUser.get('label')){
            data.titleDialog = 'quick-connect.remove-current-account-title';
            data.bodyDialog = 'quick-connect.remove-current-account-body';
        }

        this.translate.get(data.titleDialog).subscribe((translation: string) =>
            label === this.currentUser.get('label') ? data.titleDialog = translation : data.titleDialog = translation + ' ' + label);
        this.translate.get(data.bodyDialog).subscribe((translation: string) => data.bodyDialog = translation);
        this.translate.get(data.labelTrueDialog).subscribe((translation: string) => data.labelTrueDialog = translation);
        this.translate.get(data.labelFalseDialog).subscribe((translation: string) => data.labelFalseDialog = translation);

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.authenticationService.deleteQuickConnectUsers(this.localUsers, label);
                if (label === this.currentUser.get('label')) {
                    this.router.navigate(['logout']);
                }
            }
            this.confirmDialogRef = null;
        });
    }

    public postLogout(): void {
        this.isAuthenticated = false;
    }
}
