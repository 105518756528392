declare var tarteaucitron: any;
declare global {
    interface Window {
        tarteaucitronForceLanguage: string;
    }
}

import { take, takeUntil } from 'rxjs/operators';
import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import {
    LangChangeEvent,
    TranslateService,
    TranslationChangeEvent,
} from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ServiceInstantiator } from './service-instantiator.class';
import { TralaTranslationLoaderService } from './trala-translation-loader.service';
import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import {
    brand,
    brandTitle,
    defaultLanguage,
    langs,
    GATracking,
    trackingIDFromSettings,
    matomoTrackingIDFromSettings,
    matomoTracking,
    tarteaucitronConf,
} from './settings';
import { DataEntity, OctopusConnectService } from 'octopus-connect';
import { AuthenticationService } from '@modules/authentication';
import { CommunicationCenterService } from '@modules/communication-center';
import { AccountManagementProviderService } from '@modules/account-management';
import { Angulartics2GoogleGlobalSiteTag, Angulartics2Matomo } from 'angulartics2';
import { DynamicMenuService } from './navigation/services/dynamic-menu.service';
import { MainMenuOpeningModeEnum } from './navigation/models/main-menu-opening-mode.enum';
import { MatSidenav } from '@angular/material/sidenav';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    lang: string;
    browserLang: string;
    themeColor = 'red';
    route: string;
    brand: string = brand;
    brandTitle: string = brandTitle;
    trackingID: string = trackingIDFromSettings;
    GATracking: boolean = GATracking;
    matomoTrackingID: string = matomoTrackingIDFromSettings;
    matomoTracking: boolean = matomoTracking;
    tarteaucitronConf: object = tarteaucitronConf;
    public isAuthenticated = false;
    public footerVisible = false;
    public appVisible = true; // hide all the app content
    public sideNavMode: MainMenuOpeningModeEnum;
    public sideNavIsOpen: boolean;
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // Private
    private _unsubscribeAll: Subject<void>;
    private initialHref: string;

    // keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    private noCookieLawSubscription: Subscription;

    public username: string;
    public concept: string;

    @ViewChild('sidenav') public sidenav: MatSidenav;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private translationLoader: TralaTranslationLoaderService,
        private serviceInstantiator: ServiceInstantiator,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private location: Location,
        private connector: OctopusConnectService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private usersProvider: AccountManagementProviderService,
        private titleService: Title,
        angularticsGA: Angulartics2GoogleGlobalSiteTag,
        angularticsMatomo: Angulartics2Matomo,
        private dynamicMenuService: DynamicMenuService,
        private renderer: Renderer2
    ) {
        this.initialHref = window.location.href;
        router.events.subscribe((val) => {
            if (location.path() !== '') {
                this.route = location.path();
            } else {
                this.route = 'Home';
            }
        });

        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.browserLang = defaultLanguage;
        // TODO force lang from setting temporary for ubolino
        // this.browserLang = defaultLang.indexOf(navigator.language.substring(0, 2)) !== -1 ? navigator.language.substring(0, 2) : defaultLanguage;

        // Add languages
        this._translateService.addLangs(defaultLang);

        // Set the default language
        this._translateService.setDefaultLang(this.browserLang);

        // Use a language
        this.lang = localStorage.getItem('lang') || this.browserLang;
        this.document.documentElement.lang = this.lang;
        this.connector.setLanguage(this.lang);
        this._translateService.use(this.lang);
        // Set the navigation translations
        this.translationLoader.loadTranslations(...defaultLang);

        this._translateService.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.connector.setLanguage(event.lang);
                this.document.documentElement.lang = event.lang;
                this._translateService.currentLang = event.lang;
                // init tarteaucitron and update tarteaucitron language
                this.tarteaucitronInit();
            }
        );

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        // cookies managment with tarteaucitron
        if (this.GATracking) {
            this.loadTartaucitron().onload = () => {
                this.tarteaucitronInit();
            };
        }

        // Matomo tracking
        if (this.matomoTracking) {
            this.loadMatomo(this.matomoTrackingID);
            angularticsMatomo.startTracking();
        }

        // Google Analytics tracking
        if (this.GATracking) {
            angularticsGA.startTracking();
        }

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.username = data.get('nickname');
                    this.concept = data.get('config')?.concept ? data.get('config').concept : '';
                    this.isAuthenticated = true;
                } else {
                    this.postLogout();
                }
            });

        /**
         * listen if the main footer must be visible or not
         */
        this.communicationCenter
            .getRoom('footer')
            .getSubject('visibility')
            .subscribe((footerVisible: boolean) => {
                // this.footerVisible = footerVisible;
            });

        this.communicationCenter
            .getRoom('app')
            .getSubject('visibility')
            .subscribe((isVisible: boolean) => {
                this.appVisible = isVisible;
            });
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.document.body.classList.add(this.brand);
        const element = this.document.querySelector<HTMLLinkElement>(
            'link[ rel *= "icon"]'
        );
        if (element) {
            element.href = 'assets/' + this.brand + '/favicon.png?v=4';
        }
        this.setTitle(this.brandTitle);
        // Check (prepare data) if user is Logged or Anonymous
        this.connector
            .authenticated('http')
            .pipe(take(1))
            .subscribe(
                (userData: DataEntity) => {
                    this.authService.loggedUser = userData;
                    this.authService.isAuthenticated = true;
                    this.communicationCenter
                        .getRoom('authentication')
                        .next('userData', userData);
                },
                () => {
                    // Nothing to do here
                }
            );

        this.initializeMenu();
    }

    ngAfterViewInit(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .getSubject('addClass')
            .subscribe((classCss: string | string[]) => {
                const classes = Array.isArray(classCss) ? classCss : [classCss];
                this.document.body.classList.add(
                    ...classes.filter(
                        (loopedClass) =>
                            !this.document.body.classList.contains(loopedClass)
                    )
                );
            });
        this.communicationCenter
            .getRoom('skeleton')
            .getSubject('removeClass')
            .subscribe((classCss: string[]) => {
                const classes = Array.isArray(classCss) ? classCss : [classCss];
                this.document.body.classList.remove(...classes);
            });
    }

    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public postLogout(): void {
        this.isAuthenticated = false;
    }

    public setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

    public loadGoogleAnalytics(trackingID: string): void {
        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute(
            'src',
            `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
        );

        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\', {'anonymize_ip': true, 'cookie_expires': 33696000});`;

        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
    }

    public loadMatomo(trackingID: string): void {
        const matomoScript = document.createElement('script');
        matomoScript.setAttribute('type', `text/javascript`);
        matomoScript.innerText = `var _paq = window._paq || [];_paq.push([\'enableLinkTracking\']);(function() {var u=\'//matomo.tralalere.com/\';_paq.push([\'setTrackerUrl\', u+\'matomo.php\']);_paq.push([\'setSiteId\', \'${trackingID}\']);var d=document, g=d.createElement(\'script\'), s=d.getElementsByTagName(\'script\')[0];g.type=\'text/javascript\'; g.async=true; g.defer=true; g.src=u+\'matomo.js\'; s.parentNode.insertBefore(g,s);})();`;

        document.documentElement.firstChild.appendChild(matomoScript);
    }

    public loadTartaucitron(): HTMLScriptElement {
        const tarteaucitronScript = document.createElement('script');
        tarteaucitronScript.setAttribute(
            'src',
            `https://www.tralalere.com/tarteaucitron/tarteaucitron.js`
        );
        this.renderer.appendChild(document.body, tarteaucitronScript);
        return tarteaucitronScript;
    }

    public tarteaucitronInit(): void {
        window.tarteaucitronForceLanguage = this._translateService.currentLang;
        tarteaucitron.init(this.tarteaucitronConf);
        // Google analytics
        if (this.GATracking) {
            tarteaucitron.user.gtagUa = this.trackingID;
            // tarteaucitron.user.gtagCrossdomain = ['example.com', 'example2.com'];
            tarteaucitron.user.gtagMore = function (): void {
                /* add here your optionnal gtag() */
            };
            (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
        }
    }

    private initializeMenu(): void {
        this.sideNavMode = this.dynamicMenuService.mode;
        this.sideNavIsOpen = this.dynamicMenuService.isOpen;

        if (this.sideNavMode === 'over') {
            this.communicationCenter
                .getRoom('mainMenu')
                .getSubject('onMenuLinkClick')
                .subscribe(() => {
                    this.communicationCenter
                        .getRoom('mainMenu')
                        .next('toggle', 'false');
                });
        }

        this.communicationCenter
            .getRoom('mainMenu')
            .getSubject('toggle')
            .subscribe((state?: boolean) => {
                if (state === true) {
                    this.sidenav.open();
                } else if (state === false) {
                    this.sidenav.close();
                } else {
                    this.sidenav.toggle();
                }
            });
    }
}
