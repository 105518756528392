import {Injectable} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center/index';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {AuthenticationService} from '@modules/authentication/index';
import {onboarding} from '../../settings';
import {combineLatest, Observable, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {TypedDataEntityInterface} from '../models/octopus-connect/typed-data-entity.interface';
import {OctopusConnectService} from 'octopus-connect';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {UpsellComponent} from './upsell/upsell.component';

const LICENSE_ENDPOINT = 'licenses';
const INSTITUTION_ENDPOINT = 'institutions';

export interface SnackBarDataInjectedInterface {
    dismiss: () => void;
    isInSnackbar: boolean;
}

export interface LicenseTypeInterface {
    'id': string | number;
    'label': string;
}

export interface LicenseDataInterface {
    'id': string | number;
    'code': string;
    // TODO si utilisé
    'access': unknown;
    'date': Date;
    'expirationDate': Date;
    // User id
    'uid': string | number;
    'expired': boolean;
    'type'?: LicenseTypeInterface;
}

export type LicenseDataEntity = TypedDataEntityInterface<LicenseDataInterface>;

export interface InstitutionDataEntityAttributes {
    license: { id: string, startDate: Date, endDate: Date, type: string, uid?: string, };
}


export type InstitutionDataEntity = TypedDataEntityInterface<InstitutionDataEntityAttributes>;

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    private currentUser: UserDataEntity;
    private logout$ = new Subject<void>();

    constructor(
        private communicationCenter: CommunicationCenterService,
        private authenticationService: AuthenticationService,
        private octopusConnect: OctopusConnectService,
        private snackBar: MatSnackBar
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: UserDataEntity) => {
                this.currentUser = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    private userInstitutions$: () => Observable<InstitutionDataEntity[]> = () => this.octopusConnect
        .loadCollection(INSTITUTION_ENDPOINT)
        .pipe(
            map(licenseCollection => licenseCollection.entities.slice() as InstitutionDataEntity[]),
            map(institutionList => (institutionList || []))
        )

    private userLicences$: () => Observable<LicenseDataEntity[]> = () => this.octopusConnect
        .loadCollection(LICENSE_ENDPOINT)
        .pipe(
            map(licenseCollection => licenseCollection.entities.slice() as LicenseDataEntity[])
        )

    private isUserOwnNoPaidLicences$: () => Observable<boolean> = () => this.userLicences$()
        .pipe(
            map(licences => licences.every(licence => {
                const label = licence.get('type')?.label;
                return label !== 'Class' && label !== 'Institution';
            }))
        )

    private isUsersInstitutionOwnNoPaidLicences$: () => Observable<boolean> = () => this.userInstitutions$()
        .pipe(
            map(institutions => institutions.map(i => i.get('license')?.type)),
            map(licences => licences.every(licence => licence !== 'Class' && licence !== 'Institution'))
        )

    public isUserHasFreeLicence$: () => Observable<boolean> = () =>
        combineLatest([
            this.isUserOwnNoPaidLicences$(),
            this.isUsersInstitutionOwnNoPaidLicences$()
        ]).pipe(
            map(([isUserOwnNoPaidLicences, isUserInstitutionOwnNoPaidLicences]) =>
                isUserOwnNoPaidLicences
                && isUserInstitutionOwnNoPaidLicences
            )
        )

    private userProgressionLevel$: () => Observable<number> = () => this.communicationCenter
        .getRoom('gamification')
        .getSubject('levelData')
        .pipe(
            map(({level}: { coin: number, level: number }) => level)
        )

    private postAuthentication(): void {
        this.emitNews();
    }

    private postLogout(): void {
        this.logout$.next();
        this.logout$.complete();
        this.logout$ = new Subject();
    }

    private emitNews(): void {
        const availableNews = onboarding.hasOwnProperty(this.authenticationService.accessLevel)
            ? onboarding[this.authenticationService.accessLevel]
            : onboarding['default'];

        if ((availableNews ?? []).includes('upsell')) {
            this.emitUpsellNews();
        }
    }

    private emitUpsellNews(): void {
        const isOdd = (n: number) => n % 2 === 1;

        combineLatest([
            this.userProgressionLevel$(),
            this.isUserHasFreeLicence$()
        ]).pipe(
            takeUntil(this.logout$),
            filter(([_userProgressionLevel, isUserHasFreeAccount]) => isUserHasFreeAccount),
            filter(([userProgressionLevel, _isUserHasFreeAccount]) => isOdd(userProgressionLevel))
        ).subscribe(() => {
            const data: SnackBarDataInjectedInterface = {
                dismiss: () => {
                    snackBarRef.dismiss();
                },
                isInSnackbar: true
            };

            const snackBarRef = this.snackBar.openFromComponent(UpsellComponent as any, {
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                duration: 15000,
                data
            });
        });
    }
}
