import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { CamelCasePipe } from './camel-case.pipe';
import { SnakeCasePipe } from './snake-case.pipe';

@NgModule({
    declarations: [
        KeysPipe,,
        CamelCasePipe,
        SnakeCasePipe,
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        CamelCasePipe,
        SnakeCasePipe,
    ]
})
export class FusePipesModule
{
}
