import {Component, Input} from '@angular/core';
import {MainMenuElementBatchInterface} from 'app/navigation/models/main-menu-element-batch.interface';

@Component({
    selector: 'app-main-menu-element-batch',
    templateUrl: './main-menu-element-batch.component.html'
})
export class MainMenuElementBatchComponent {
    @Input() public element: MainMenuElementBatchInterface;
    @Input() public index: number;
    public isOpened = true;

    public toggle(): void {
        this.isOpened = !this.isOpened;
    }
}
