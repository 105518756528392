import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: '[registrationCode]'
})
export class RegistrationCodeDirective {

    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        const input = event.target as HTMLInputElement;

        // code format : XXXX-XXXX-XXXX
        if (event['inputType'] !== 'deleteContentBackward'){
            if (event['inputType'] !== 'Backspace' && (input.value.length === 4 || input.value.length === 9)){
                input.value += '-';
            }
        }

        if (input.value.length > 14 ){
            input.value = input.value.substr(0, 14);
        }
    }
}
