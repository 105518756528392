import { Component } from '@angular/core';

@Component({
  selector: 'app-pretty-card',
  templateUrl: './pretty-card.component.html',
  styleUrls: ['./pretty-card.component.scss']
})
export class PrettyCardComponent {

}
