import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-full-page',
    templateUrl: './full-page.component.html',
    styleUrls: ['./full-page.component.scss']
})
export class FullPageComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
