import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CollectionOptionsInterface} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-followed-filter',
    templateUrl: './followed-filter.component.html',
    styleUrls: ['./followed-filter.component.scss']
})
export class FollowedFilterComponent implements OnInit, AfterViewInit {
    private optionsInterface: CollectionOptionsInterface;
    public titleFilter = new UntypedFormControl();
    public commentFilter = new UntypedFormControl();
    public typeFilter = new UntypedFormControl();
    public beginDate = new UntypedFormControl();
    public endDate = new UntypedFormControl();


    filterWorkgroupActive: string;
    filterGroupActive: string;
    public learnersList: any[] = [];

    public customFilter = {
        dueDateControl: new UntypedFormControl(''),
        educationalLevelControl: new UntypedFormControl(''),
        groupControl: new UntypedFormControl(''),
        startDateControl: new UntypedFormControl(''),
        workgroupControl: new UntypedFormControl(''),
    };

    public formControls = {
        title: this.titleFilter,
        type: this.typeFilter,
        comment: this.commentFilter
    };

    @Input('displayedFilters') displayedFilters: string[] = [];
    @Input('educationalLevels') educationalLevels: any[] = [];
    @Input('allStates') allStates: any[] = [];
    @Input('learnersList') learnersListService: any[] = [];
    @Input('workgroupsList') workgroupsList: any[] = [];
    @Input('groupsList') groupsList: any[] = [];
    @Input('allTypes') allTypes: any[] = [];
    @Input('schoolYearsList') schoolYearsList: any[] = [];
    @Input('currentSchoolYearBegin') currentSchoolYearBegin: any[] = [];
    @Input('schoolYearDates') schoolYearDates: string;
    @Input('countEntities') countEntities = 50;
    @Input('checkDefaultFiltersInUrl') public checkDefaultFiltersInUrl?: boolean = false;


    @Output('launchSearch') launchSearchOutput = new EventEmitter<any>();

    @ViewChild('typeSelect') typeSelect: MatSelect;
    @ViewChild('stateSelect') stateSelect: MatSelect;
    @ViewChild('groupSelect') groupSelect: MatSelect;
    @ViewChild('workgroupSelect') workgroupSelect: MatSelect;
    @ViewChild('learnerSelect') learnerSelect: MatSelect;
    @ViewChild('titleInput') titleInput: MatInput;
    @ViewChild('commentInput') commentInput: MatInput;

    constructor(private authService: AuthenticationService,
                private route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.optionsInterface = {
            filter: this.authService.accessLevel === 'learner' ? {'assignated_user': this.authService.userData.id} : {'assignator': this.authService.userData.id},
            page: 1,
            range: 10
        };
        this.learnersList = this.learnersListService;
        this.titleFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.applyFilters({value: query}, 'assignated_node_title');
            });

        this.commentFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.applyFilters({value: query}, 'comment');
            });

        this.beginDate.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.convertDate(query, 'begin_date');
            });

        this.endDate.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.convertDate(query, 'end_date');
            });

        if (this.displayFilters('schoolyear') && this.schoolYearDates !== '') {
            // default sort to current year
            this.optionsInterface.filter['schoolYearDates'] = this.schoolYearDates;
        }
    }

    ngAfterViewInit(): void {
        if (this.checkDefaultFiltersInUrl) {
            this.route.queryParams.subscribe(params => {
                const filterLabel = {
                    'title': 'assignated_node_title',
                    'type': 'assignments_type'
                };

                Object.keys(params)
                    .filter(key => Object.keys(this.formControls).includes(key))
                    .forEach((key) => {
                        let value = params[key];
                        // Cas particulier, le "type" est un label, il nous faut l'id
                        if (key === 'type') {
                            value = this.allTypes.find(t => t.label === params[key])['id'];
                        }

                        // Les filtres visibles dans l'ihm (FI) ne sont pas les filtres que l'ont envoie au server (FS).
                        // les FS sont dynamiquement généré dans les FI change
                        // Donc quand on les initialise au démarrage :
                        // Il faut changer initialValues pour les FI
                        this.formControls[key].setValue(value);
                        // Mais aussi optionsInterface.filter pour les FS
                        this.optionsInterface.filter[filterLabel[key]] = value;
                    });
                this.launchSearch();
            });
        } else {
            this.launchSearch();
        }
    }

    convertDate(data: any, type: string): void {
        if (data) {
            // date will be the day just before the choosen one in the picker at 23:00
            this.applyFilters({value: Date.parse(data) / 1000}, type);
        } else {
            this.applyFilters({value: null}, type);
        }
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.includes(name);
    }

    public selectFilter(event, field): void {
        if (event.value === 'all') {
            delete this.optionsInterface.filter[field];
        } else {
            this.optionsInterface.filter[field] = event.value;
        }

        this.blurElementRef(field);
    }

    public blurElementRef(type?: string): void {
        if (this.typeSelect) {
            this.typeSelect.close();
        }
        if (this.stateSelect) {
            this.stateSelect.close();
        }
        if (this.groupSelect) {
            this.groupSelect.close();
        }
        if (this.workgroupSelect) {
            this.workgroupSelect.close();
        }
        if (this.learnerSelect) {
            this.learnerSelect.close();
        }

        if (type === 'comment') {
            this.commentInput.focus();
        } else {
            this.titleInput.focus();
        }
    }

    selectGroups(event, type): void {
        switch (type) {
            case 'group':
                this.filterGroupActive = event.value;
                break;
            case 'workgroup':
                this.filterWorkgroupActive = event.value;
                break;
        }

        this.learnersList = this.learnersListService;

        if (this.filterGroupActive && this.filterGroupActive !== 'all' && this.filterGroupActive !== '') {
            this.learnersList = this.learnersList.filter((learner) => learner.groups.indexOf(this.filterGroupActive) !== -1);
        }
        if (this.filterWorkgroupActive && this.filterWorkgroupActive !== 'all' && this.filterWorkgroupActive !== '') {
            this.learnersList = this.learnersList.filter((learner) => learner.workgroups.indexOf(this.filterWorkgroupActive) !== -1);
        }

        if (this.learnersList !== this.learnersListService) {
            const learnerIds = this.learnersList
                .map((learner) => learner.id);
            this.applyFilters({value: learnerIds.join(',')}, 'assignated_user');
        } else {
            this.applyFilters({value: 'all'}, 'assignated_user');
        }
    }

    selectLearners(event): void {
        this.applyFilters({value: event.value.join(',')}, 'assignated_user');
    }

    applyFilters(event, type): void {
        this.blurElementRef(type);
        if (event.value === 'all') {
            delete this.optionsInterface.filter[type];
        } else {
            this.optionsInterface.filter[type] = event.value;
        }
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    public localizedState(state: string): string {
        return `assignment.state.${state}`;
    }

    launchSearch(): void {
        this.launchSearchOutput.emit(this.optionsInterface);
    }

    public displaySchoolYear(schoolyear: string): string {
        const nextSchoolyear = +schoolyear + 1;
        return schoolyear + '-' + nextSchoolyear;
    }
}
