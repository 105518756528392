import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GamificationService} from '../gamification.service';
import {take} from 'rxjs/operators';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-badge-type-list',
    templateUrl: './badge-type-list.component.html'
})
export class BadgeTypeListComponent implements OnInit {
    public badges: BadgeTypeViewModel[] = [];

    constructor(
        public gamificationService: GamificationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private connector: OctopusConnectService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        // if badges already loaded
        if (this.gamificationService.badgesTypes){
            for (const entity of this.gamificationService.badgesTypes) {
                this.translateNameandSetHasNew(entity);
            }
        } else {
            this.connector.loadCollection('badges-type').pipe(take(1)).subscribe(data => {
                for (const entity of data.entities) {
                    this.translateNameandSetHasNew(entity);
                }
            });
        }
    }

    goToBadgeTypeGallery(badgeType: BadgeTypeViewModel): void {
        this.router.navigate(['badge-gallery', badgeType.badgeTypeId], {relativeTo: this.activatedRoute});
    }

    translateNameandSetHasNew(entity: DataEntity): void{
        if (this.gamificationService.settings.badgeTypes.includes(entity.get('name'))){
            let name;
            if (entity.get('location_key') && entity.get('location_key') !== '') {
                this.translate.get(entity.get('location_key')).subscribe((translation: string) => name = translation);
            } else {
                name = entity.get('name');
            }
            if (entity.get('name') === 'créature') {
                this.badges.push(new BadgeTypeViewModel(name, true, entity.id));
            } else {
                this.badges.push(new BadgeTypeViewModel(name, false, entity.id));
            }
        }
    }
}

// Only here to type the view models data
class BadgeTypeViewModel {
    public badgeTypeName = '';
    public badgeTypeId;
    public badgeTypeHasNewItem = false;

    constructor(name: string, hasNew: boolean, id: string | number) {
        this.badgeTypeName = name;
        this.badgeTypeHasNewItem = hasNew;
        this.badgeTypeId = id;
    }
}