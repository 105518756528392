export class SvgIcon {
    [key: string]: string
}

export const SVGICONDEFAULT: SvgIcon = {
    'activity': 'activity',
    'add': 'add',
    'alert': 'alert',
    'alert_circle': 'alert_circle',
    'analytics': 'analytics',
    'app': 'matching',
    'arrow_right': 'arrow_right',
    'assessment': 'evaluation',
    'assignment': 'assignment',
    'audio': 'audio',
    'back': 'back',
    'book_open': 'book_open_outline',
    'brand': 'brand',
    'brand_square': 'brand_square',
    'chat_close': 'chat_close',
    'chat_happy': 'chat_happy',
    'chat_sad': 'chat_sad',
    'check': 'check',
    'chevron': 'chevron',
    'help': 'help',
    'circle': 'circle',
    'class_add': 'class_add',
    'close': 'close',
    'close_menu': 'close_menu',
    'collections_bookmark': 'transition',
    'contacts': 'classe',
    'corpus': 'corpus',
    'corpus_pdf': 'corpus',
    'correct': 'success',
    'coin': 'coin',
    'cross': 'cross',
    'crt': 'reponsecourte',
    'dashboard': 'dashboard',
    'delete': 'delete',
    'diamond': 'diamond',
    'document': 'document',
    'download': 'download_outline',
    'drag': 'drag',
    'dropdown': 'dropdown',
    'earth': 'earth',
    'edit': 'edit',
    'exit': 'exit',
    'file_download': 'file_download',
    'fill_in_blanks': 'remplir_blancs',
    'flag': 'flag',
    'folder': 'folder',
    'followed': 'assignment',
    'free_answer': 'reponsecourte',
    'fullscreen': 'fullscreen',
    'fullscreen_circle': 'fullscreen_circle',
    'fullscreen_exit': 'fullscreen_exit',
    'funambule': 'funambule',
    'generateur': 'generateur',
    'gestioncompte': 'classe',
    'group': 'group',
    'heart': 'heart',
    'homepage': 'home',
    'home': 'home',
    'homework': 'devoir',
    'hourglass': 'hourglass',
    'icon_arrow_back': 'arrow_back',
    'icon-lesson-add': 'add',
    'icon_progression_not_acquired': 'fill_progression_not_acquired',
    'icon_progression_to_do': 'fill_progression_to_do',
    'icon_progression_so_close': 'fill_progression_so_close',
    'icon_progression_acquired': 'fill_progression_acquired',
    'image': 'image',
    'info': 'info',
    'institution_add': 'institution_add',
    'keyboard_arrow_up': 'keyboard_arrow_up',
    'keyboard_arrow_down': 'keyboard_arrow_down',
    'lessons': 'explore',
    'log_out': 'log_out',
    'logout': 'logout',
    'planet': 'planet',
    'assignment_menu': 'assignment_menu',
    'main_menu': 'main_menu',
    'magnify': 'search',
    'menu': 'menu',
    'montrer': 'montrer',
    'new_releases': 'document',
    'notifications': 'audio',
    'ord': 'ordonnancement',
    'ordonnancement': 'ordonnancement',
    'outils2': 'outils2',
    'pdf': 'document',
    'photo': 'ressources',
    'pin': 'pin',
    'play': 'play',
    'print': 'print',
    'projects': 'projet',
    'qcm': 'qcm',
    'qcmu': 'qcmu',
    'qcu': 'qcmu',
    'question_set': 'question_set',
    'question' : 'question',
    'rb': 'remplir_blancs',
    'recommendation': 'recommendation',
    'round_videoprojeter': 'round_videoprojeter',
    'round_videoprojeteroff': 'round_videoprojeteroff',
    'save': 'enregistrer',
    'search': 'search',
    'sort': 'sort',
    'star_0': 'star_0',
    'star_1': 'star_1',
    'step_done': 'step_done',
    'step_inprogress': 'step_inprogress',
    'step_pasfait': 'step_pasfait',
    'student_add': 'student_add',
    'submenu': 'submenu',
    'tools': 'outils',
    'tools-circle': 'cube_outline_circle',
    'trainer_add': 'trainer_add',
    'training': 'entrainement',
    'trala_btn': 'trala_btn',
    'trash': 'trash',
    'user': 'user',
    'vf': 'vraifaux',
    'video': 'ressources',
    'videoprojeter': 'videoprojeter',
    'view_day': 'matching',
    'view_list': 'ordonnancement',
    'view_stream': 'remplir_blancs',
    'view_week': 'vraifaux',
    'visibility_legalnotice': 'visibility',
    'visibility_cgurgpd': 'visibility',
    'visibility_privacy': 'visibility',
    'visibility': 'visibility',
    'visibility_off': 'visibility_off',
    'virtual_class': 'virtual_class',
    'white_circle': 'white_circle',
    'workgroup_add': 'workgroup_add',
    'wrong': 'failure',
    'Vrai': 'true',
    'Faux': 'false',
    'button-audio__icon': 'equalizer_1'
};
