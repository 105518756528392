import {Downloader, SpinePlayer, SpinePlayerConfig, StringMap} from '@esotericsoftware/spine-player';

export class PlayerConfig implements SpinePlayerConfig {
    jsonUrl: string;
    jsonField: string;
    /*** All of the next items of SpinePlayerConfig are marked optionnal in their doc but need to be implemented by the interface */
    binaryUrl: string;
    atlasUrl: string;
    rawDataURIs: StringMap<string>;
    animation: string;
    animations: string[];
    defaultMix: number;
    skin: string;
    skins: string[];
    premultipliedAlpha: boolean;
    showControls: boolean;
    showLoading: boolean;
    alpha: boolean;
    debug: { bones: boolean; regions: boolean; meshes: boolean; bounds: boolean; paths: boolean; clipping: boolean; points: boolean; hulls: boolean; };
    backgroundColor: string;
    fullScreenBackgroundColor: string;
    backgroundImage: {url: string; x: number; y: number; width: number; height: number;};
    mipmaps: true;
    controlBones: string[];
    success: (player: SpinePlayer) => void;
    error: (player: SpinePlayer, msg: string) => void;
    frame: (player: SpinePlayer, delta: number) => void;
    update: (player: SpinePlayer, delta: number) => void;
    draw: (player: SpinePlayer, delta: number) => void;
    loading: (player: SpinePlayer, delta: number) => void;
    downloader: Downloader;
    viewport: { 
        x: number; 
        y: number; 
        width: number; 
        height: number; 
        padLeft: string | number;
        padRight: string | number; 
        padTop: string | number; 
        padBottom: string | number; 
        debugRender: boolean; 
        transitionTime: number; 
        animations: StringMap<any>;
     };

}