import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import {AccountManagementProviderService} from './account-management-provider.service';
import {AuthenticationService} from '@modules/authentication';
import {ProfileService} from './profile/profile.service';

import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {DataEntity} from 'octopus-connect';
import {FuseSharedModule} from '@fuse/shared.module';

import {FuseProfileComponent} from './profile/profile.component';
import {FuseProfileAboutComponent} from './profile/tabs/about/about.component';
import {FuseFormsComponent} from './profile/tabs/edit-profile/forms.component';
import {UploadFileComponent} from './profile/upload-file/upload-file.component';

import {ValidateEmailModule} from '@modules/account-management/core/validate-email/validate-email.module';
import {FuseValidateEmailComponent} from '@modules/account-management/core/validate-email/validate-email.component';
import {FuseRequestSubscribeComponent} from '@modules/account-management/core/request-subscribe/request-subscribe.component';
import {FuseMailConfirmComponent} from '@modules/account-management/core/mail-confirm/mail-confirm.component';
import {SharedModule} from '../../../shared/shared.module';
import {ShowProfileComponent} from './profile/tabs/show-profile/show-profile.component';
import {ProfileDeleteConfirmModalComponent} from './profile/profile-delete-confirm-modal/profile-delete-confirm-modal.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {NgHcaptchaModule} from 'ng-hcaptcha';
import {FeedbackComponent} from './components/feedback/feedback.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {LinkChildComponent} from './components/link-child/link-child.component';
import {UnlinkParentComponent} from '@modules/account-management/core/components/unlink-parent/unlink-parent.component';
import { RegisterEaslyComponent } from './register-easly/register-easly.component';
import {FuseRegisterComponent} from '@modules/account-management/core/register/register.component';
import { JoinClassComponent } from './profile/tabs/join-class/join-class.component';
import {
    CanAccessToAuthenticationFeaturesGuard
} from "@modules/authentication/core/guards/can-access-to-authentication-features.guard";

const routes: Routes = [
    {
        path: 'profile',
        canActivate: [IsUserLogged],
        component: FuseProfileComponent,
        children:
            [
                {path: '', redirectTo: 'show-profile', pathMatch: 'full'},
                {
                    path: 'show-profile', component: ShowProfileComponent,
                    resolve: {
                        profile: ProfileService
                    }
                },
                {
                    path: 'link-child',
                    component: LinkChildComponent
                },
                {
                    path: 'unlink-parent',
                    component: UnlinkParentComponent
                }
            ]
    },
    {
        path: 'feedback', component: FeedbackComponent
    },
    {
        path: 'editprofile',
        canActivate: [IsUserLogged],
        component: FuseFormsComponent
    },
    {
        path: 'register', // standard register create account
        component: FuseRegisterComponent // FuseRegisterComponent
    },
    {
        path: 'register-user', // register with only a pseudo and without password
        component: RegisterEaslyComponent,
        canActivate: [CanAccessToAuthenticationFeaturesGuard],
    },
    {
        path: 'requestsubcribe',
        component: FuseRequestSubscribeComponent
    },
    {
        path: 'user/email-validation/:token',
        component: FuseValidateEmailComponent
    }
];

@NgModule({
    imports: [
        NgHcaptchaModule,
        CommonModule,
        FormsModule,
        MatProgressSpinnerModule,
        FuseSharedModule,
        SharedModule,
        ValidateEmailModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        FuseProfileComponent,
        ShowProfileComponent,
        FuseRegisterComponent,
        RegisterEaslyComponent,
        FuseProfileAboutComponent,
        FuseFormsComponent,
        UploadFileComponent,
        FuseRequestSubscribeComponent,
        FuseMailConfirmComponent,
        ProfileDeleteConfirmModalComponent,
        FeedbackComponent,
        LinkChildComponent,
        UnlinkParentComponent,
        JoinClassComponent
    ],
    providers: []
})
export class AccountManagementModule {

    static forRoot(): ModuleWithProviders<AccountManagementModule> {

        return {
            ngModule: AccountManagementModule,
            providers: [
                AccountManagementProviderService,
                AuthenticationService,
                ProfileService
            ]
        };
    }

    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    private postLogout(): void {
    }

    private postAuthentication(): void {
        // TODO menu
        // // redirect dashboard'url to basic page url
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'feedback',
        //     'title': 'feedback',
        //     'translate': 'generic.feedback',
        //     'type': 'link',
        //     'icon': '',
        //     'url': '/feedback',
        // });
        //
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'unlink-parent',
        //     'title': 'unlink-parent',
        //     'translate': 'unlink_parent.title',
        //     'type': 'item',
        //     'icon': '',
        //     'url': '/profile/unlink-parent',
        // });
        //
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'link-child',
        //     'title': 'link-child',
        //     'translate': 'link_child.title',
        //     'type': 'item',
        //     'icon': '',
        //     'url': '/profile/link-child',
        // });

    }
}
