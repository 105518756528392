import { BadgeType } from './../definitions';
import { DataCollection, DataEntity } from 'octopus-connect';
import { GamificationService } from './../gamification.service';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-my-avatar',
    templateUrl: './my-avatar.component.html'
})
export class MyAvatarComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private gamificationService: GamificationService, private communicationCenter: CommunicationCenterService) {
    }

    ngOnInit(): void {
        this.gamificationService.loadAvatar();
    }
    ngAfterViewInit(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'position-top-lower-for-content');
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'position-top-lower-for-content');
    }
}
