import { NgModule } from '@angular/core';

import {CommonModule} from '@angular/common';
import {ErrorReportingModule} from 'fuse-core/components/error-reporting/error-reporting.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TtsModule} from '../../../app/tts/tts.module';
import {FuseSelectionDialogComponent} from './selection-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedMaterialModule} from '../../../app/shared/shared-material.module';

@NgModule({
    declarations: [
        FuseSelectionDialogComponent
    ],
    imports: [
        CommonModule,
        ErrorReportingModule,
        FormsModule,
        TtsModule,
        TranslateModule,
        ReactiveFormsModule,
        SharedMaterialModule
    ],
})
export class FuseSelectionDialogModule
{
}
