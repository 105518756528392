import {Component, Input, Type, ViewChild, ComponentFactoryResolver, OnInit} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {DynamicComponentDirective} from '@fuse/directives/dynamic-component/dynamic-component.directive';

@Component({
    selector: 'app-dynamic',
    templateUrl: './dynamic.component.html',
    styleUrls: ['./dynamic.component.scss']
})
export class DynamicComponent implements OnInit {
    @ViewChild(DynamicComponentDirective, { static: true }) dynamicDirective: DynamicComponentDirective;

    @Input('room') room: string;
    @Input('subject') subject: string;
    @Input('params') params: object;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private communicationCenter: CommunicationCenterService) {
    }

    ngOnInit(): void {
        this.communicationCenter
            .getRoom(this.room)
            .getSubject(this.subject)
            .subscribe((component: Component) => {
                this.loadComponent(component);
            });
    }

    public loadComponent(component: Component): void {

        try {
            if (component) {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(<any>component);

                const viewContainerRef = this.dynamicDirective.viewContainerRef;
                viewContainerRef.clear();

                const componentRef = viewContainerRef.createComponent(componentFactory);

                for (const key in this.params) {
                    componentRef.instance[key] = this.params[key];
                }
            } else {
                throw new Error(component + 'Component Reference not initialized.');
            }
        } catch (e) {
            console.error(e);
        }
    }
}
