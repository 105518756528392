import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-manage-connect-page',
    templateUrl: './manage-connect-page.component.html'
})
export class ManageConnectPageComponent implements OnInit {

    localUsers = [];
    settingsAuth: { [key: string]: any };

    constructor(
        private authenticationService: AuthenticationService,
    ) {
        this.settingsAuth = this.authenticationService.settings;
    }

    ngOnInit(): void {
        this.localUsers = this.authenticationService.getQuickConnectUsers();
        if (this.localUsers === null){ this.localUsers = []; }
    }
}
