import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {GamificationService} from '../gamification.service';
import {DataEntity} from 'octopus-connect';
import {Creature} from '../definitions';
import {Observable, combineLatest} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-accessories-popup',
    templateUrl: './accessories-popup.component.html',
    styleUrls: ['./accessories-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccessoriesPopupComponent implements OnInit {
    public get isSaveButtonActive(): boolean {
        return this.getDifferences().length > 0;
    }

    private _origUniverses: DataEntity[] = [];
    private _universes: DataEntity[] = [];
    private _origAccessories: DataEntity[] = [];
    private _accessories: DataEntity[] = [];
    public isLoading = false;

    @Output()
    saveUserImage = new EventEmitter<boolean>();

    @Output()
    update = new EventEmitter<Array<DataEntity> | null>();

    private _creature: Creature;
    @Input()
    set creature(creature: Creature) {
        this._creature = creature;
    }

    get creature(): Creature {
        return this._creature;
    }

    @Input()
    set accessories(accessories: Array<DataEntity>) {
        this._origAccessories = accessories;
        this.resetAccessoriesDifferences();
    }

    get accessories(): Array<DataEntity> {
        return this._accessories;
    }

    @Input()
    set universes(universes: Array<DataEntity>) {
        this._origUniverses = universes;
        this.resetUniversesDifferences();
    }

    get universes(): Array<DataEntity> {
        return this._universes;
    }

    constructor(
        private gamificationService: GamificationService
    ) {
    }

    ngOnInit(): void {
        // On affiche le selecteur sur l'univers déjà sauvé sur la créature:
        this.universes.forEach(u => u.set('selected', +u.id === +this.creature.univers.id));
    }

    switchTab(tabName: 'accessories' | 'universes'): void {
        this.gamificationService.activeTab = tabName;
    }

    openPopup(): void {
        this.gamificationService.isShowPopup = true;
    }

    closePopup(): void {
        if (this.isLoading) {
            return;
        }

        this.gamificationService.accessoriesCache = {};
        this.gamificationService.isShowPopup = false;
        this.gamificationService.activeTab = 'accessories';
        // load accessories previously saved
        this.creature.loadAccessories(true);
        this.emitUpdate();
    }

    onAccessoryClick(item: DataEntity): void {
        if (this.isLoading) {
            return;
        }
        if (item.attributes.unLocked === true && item.attributes.selected === true) {
            item.set('selected', false);
            this.emitUpdate();
        } else {
            if (item.attributes.unLocked === true) {
                this.addAccessoryOnAvatar(item);
            } else if (this.gamificationService.userPoints >= item.attributes.price) {
                this.gamificationService.openBuyPopup(item, (itemBought) => {
                    // we store previous accessorie config before buy accessorie if user not save we will restore it
                    this.gamificationService.originalAccessoriesBeforeBuyingAnotherOne = [...this.accessories.filter(res => res.attributes.selected === true)];
                    // item was bought we put it on avatar without saving : user can save
                    this.addAccessoryOnAvatar(itemBought);
                });
            }
        }
    }

    /**
     * add the accessory on current avatar
     * @param item : accessories data entity
     */
    private addAccessoryOnAvatar(item: DataEntity): void {
        item.set('selected', true);
        // Because of a reload of the component, the paid accessory are reset so we add it in a "cache" ti re set the acessory on the avatar
        if (item && item.attributes.stuffType) {
            this.gamificationService.accessoriesCache[item.attributes.stuffType.name] = item;
        }
        // put all previously at true to false
        this.accessories.filter(ac =>
            ac.id !== item.id &&
            (!ac.attributes.stuffType && !item.attributes.stuffType || ac.attributes.stuffType.id === item.attributes.stuffType.id)
        ).forEach(ac => ac.set('selected', false));
        this.emitUpdate();
    }

    /**
     * add the universe on current avatar
     * @param item : universe data entity
     */
    private addUniverseOnAvatar(item: DataEntity): void {
        item.set('selected', true);
        this.creature.univers = item;
        // on recupere la reponse de la sauvegarde mais les attributesRef ne sont pas mis à jour
        // si unLocked est deja à true, le back renverra une erreur lors que l'utilisateur sauvegardera son univers
        if (item.getDiff() && item.getDiff().unLocked) {
            item.set('unLocked', !item.getDiff().unLocked);
        }
        // put all previously at true to false
        this.universes.map((universe: DataEntity) => {
            if (+universe.id === +item.id) {
                return item;
            }
            return universe;
        }).filter((universe: DataEntity) => {
            return +universe.id !== +item.id;
        }).forEach(universe => universe.set('selected', false));

        this.emitUpdate();
    }

    onUniverseClick(item: DataEntity): void {
        if (this.isLoading) {
            return;
        }
        if (item.attributes.unLocked === true && item.attributes.selected === true) {
            this.emitUpdate();
        } else {
            if (item.attributes.unLocked === true && item.attributes.selected === false) {
                item.set('selected', true);
                this.universes.filter(u =>
                    u.id !== item.id
                ).forEach(u => u.set('selected', false));
                this.creature.univers = item;
                this.emitUpdate();
            } else if (item.attributes.unLocked === false && this.gamificationService.userPoints >= item.attributes.price) {
                this.gamificationService.openBuyPopup(item, (itemBought: DataEntity) => {
                    this.addUniverseOnAvatar(item);
                });
            }
        }

    }

    emitUpdate(): void {
        const elements: DataEntity[] = [].concat(...this.accessories.filter(ac => ac.attributes.selected), this.universes.find(u => u.attributes.selected))
            .filter((element) => !!element);
        this.update.emit(elements);
    }

    getDifferences(): DataEntity[] {
        return [
            this.universes.find(u => u.attributes.selected === true && u.id !== this.creature.creature.attributes.backgroundBadge),
            ...this._origAccessories.filter(ac => !!this.accessories.find(oa => oa.id === ac.id && oa.attributes.selected !== ac.attributes.selected)),
            ...Object.values(this.gamificationService.accessoriesCache)
        ].filter(e => !!e);
    }

    reset(): void {
        if (this.isLoading || !this.isResetButtonActive) {
            return;
        }
        this.accessories.forEach(ac => ac.attributes.selected = false);
        this.update.emit([this.universes.find(u => u.attributes.selected === true)]);
    }

    private resetAccessoriesDifferences(): void {
        if (this.isLoading) {
            return;
        }
        this._accessories = this._origAccessories.slice();
    }

    private resetUniversesDifferences(): void {
        if (this.isLoading) {
            return;
        }
        this._universes = this._origUniverses.slice();
    }

    get isResetButtonActive(): boolean {
        return !!this.accessories.find(ac => ac.attributes.selected === true);
    }

    save(): void {
        if (this.isLoading) {
            return;
        }
        if (!this.isSaveButtonActive) {
            return;
        }
        this.isLoading = true;
        const accessoriesObs: Observable<DataEntity>[] = this._accessories
            .map((accessory: DataEntity ) => this.gamificationService.saveBadge(accessory));
        combineLatest(accessoriesObs).pipe(
            tap((badges: DataEntity[]) => {
                this._accessories = badges.filter((badge: DataEntity) => badge.get('type').name === 'accessoire');
                this.creature.accessories = badges.filter((badge: DataEntity) => badge.get('type').name === 'accessoire');
            }),
            mergeMap(() => {
                return this.creature.save();
            }),
            tap(() => {
                this.saveUserImage.emit(false);
            }),
            tap(() => this.isLoading = false),
        ).subscribe();
    }

    public get showPopup(): boolean {
        return this.gamificationService.isShowPopup;
    }

    public get activeTab(): 'accessories' | 'universes' {
        return this.gamificationService.activeTab;
    }
}
