import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {

    uploadForm = new UntypedFormGroup({
        file1: new UntypedFormControl()
    });

    filedata: any;
    @Input() maxSizeFile: string;
    @Output() alertSizeFileTooBig = new EventEmitter<boolean>();

    constructor(private profileProvider: ProfileService) {
    }

    fileEvent(e): void {
        if (this.isSizeTooBig(e)){
            return;
        }

        this.filedata = e.target.files[0];
        if (this.validateFileType()) {
            this.profileProvider.editfile = this.filedata;
        }
    }

    private isSizeTooBig(e): boolean{
        if (e.target.files[0].size > +(this.maxSizeFile + '000000')) {
            this.alertSizeFileTooBig.emit(true);
            return true;
        } else {
            this.alertSizeFileTooBig.emit(false);
            return false;
        }
    }

    validateFileType(): boolean {
        if (this.filedata) {
            const fileName = this.filedata.type;

            if (fileName === 'image/jpg' || fileName === 'image/jpeg' || fileName === 'image/png') {
                console.log('bon type');
                return true;
            } else {
                console.log('mauvais type');
                return false;
            }
        }
        return false;
    }
}
