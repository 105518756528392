// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video, audio, img {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/selection-dialog/selection-dialog.component.scss","webpack://./../Ubolino%20APP/src/@fuse/components/selection-dialog/selection-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;ACCJ","sourcesContent":["video, audio, img{\n    max-width: 100%;\n}\n\n","video, audio, img {\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
