import { Component } from '@angular/core';

@Component({
  selector: 'app-no-assignment',
  templateUrl: './no-assignment.component.html',
  styleUrls: ['./no-assignment.component.scss']
})
export class NoAssignmentComponent {

}
