import {GamificationModule} from './@modules/gamification/core/gamification.module';
import {LrsModule} from '@modules/lrs';
import {TtsModule} from './tts/tts.module';
import {HeaderModule} from './header/header.module';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivatedRoute, Router, RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

import {FuseSharedModule} from '@fuse/shared.module';
import {FuseSidebarModule} from '@fuse/components';
import {AppComponent} from 'app/app.component';
import {DataEntity, OctopusConnectModule} from 'octopus-connect';
import {IsUserLogged} from './guards/is-user-logged.class';
import {ServiceInstantiator} from './service-instantiator.class';
import {AccountManagementModule} from '@modules/account-management';
import {AuthenticationModule} from '@modules/authentication/core/authentication.module';
import {TralaTranslationLoaderService} from './trala-translation-loader.service';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {SharedTranslateModule} from './shared/shared-translate.module';
import {CommunicationCenterModule, CommunicationCenterService} from '@modules/communication-center';
import {defaultApiURL, defaultNodeURL, defaultRoute, frontType} from './settings';
import {NavigationModule} from './navigation/navigation.module';
// Captcha
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {NgHcaptchaModule} from 'ng-hcaptcha';
import {SvgIconList} from './themes/ubolino_app/svgIconList';
import {ActivitiesModule, LessonsModule} from '@modules/activities';
import {FullPageComponent} from 'fuse-core/components/basic-page/full-page/full-page.component';
import {IsUserAnonymous} from './guards/is-user-anonymous.class';
import {Angulartics2Module} from 'angulartics2';
import {HomePageComponent} from 'fuse-core/components/home-page/home-page.component';
import {AuthenticationService} from '@modules/authentication';
import {DatacardService} from './shared/datacard.service';
import {MatSidenavModule} from '@angular/material/sidenav';
import {AssignationModule} from '@modules/assignation';
import {IsDefaultOrDefaultLoginRoute} from './guards/is-default-or-default-login-route';
import {EventService} from './shared/event.service';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {OnboardingModule} from './shared/onboarding/onboarding.module';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: AppComponent,
        canActivate: [IsDefaultOrDefaultLoginRoute]
    },
    {
        path: 'home',
        component: HomePageComponent,
        canActivate: [IsUserLogged]
    },
    {
        path: 'page/:alias',
        component: FullPageComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        MaintenanceComponent
    ],
    imports: [
        RecaptchaV3Module,
        NgHcaptchaModule.forRoot({
            siteKey: '8858d7eb-82d8-425e-9d74-e114ceca737b',
            // languageCode: 'de' // optional, will default to browser language
        }),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),

        // import Angulartics2GoogleGlobalSiteTag in root ngModule
        Angulartics2Module.forRoot(),

        SharedTranslateModule,
        TranslateModule.forRoot(),

        // Material moment date module
        AuthenticationModule.forRoot(),
        NavigationModule.forRoot(),
        MatMomentDateModule,

        // Mat expansion panel
        MatExpansionModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatSidenavModule,

        // Fuse modules
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        HeaderModule.forRoot(),
        TtsModule,
        AccountManagementModule.forRoot(),
        ActivitiesModule.forRoot(),
        AssignationModule.forRoot(),
        LessonsModule.forRoot(),
        GamificationModule.forRoot(),
        OnboardingModule,
        CommunicationCenterModule.forRoot(),
        OctopusConnectModule.forRoot({
            defaultInterface: 'localstorage',
            maxRetry: 5,
            retryTimeout: 50000,
            liveRefreshService: 'refresh',
            configuration: {
                localstorage: {
                    prefix: 'test'
                },
                http: {
                    apiUrl: defaultApiURL,
                    useApiExtension: true,
                    headers: {
                        'Content-type': 'application/json',
                        'front': frontType
                    }
                }
            },
            map: {
                'acapela': 'http',
                'form-project': 'http',
                'feedback': 'http',
                'flashcard_search': 'http',
                'dictionary_search': 'http',
                'laboratory': 'http',
                'laboratory-theme': 'http',
                'log-book': 'http',
                'users': 'http',
                'user_search': 'http',
                'learners': 'http',
                'trainers': 'http',
                'licenses': 'http',
                'migrations': {
                    type: 'http',
                    useLanguage: true
                },
                'licenses-import': 'http',
                'groups': 'http',
                'institutions': 'http',
                'user-registration': 'http',
                'reset-password': 'http',
                'userfiles': 'http',
                'projects': 'http',
                'contact-message': 'http',
                'notification': {
                    type: 'http',
                    refreshEnabled: true
                },
                'corpus': 'http',
                'lesson': 'http',
                'activity': 'http',
                'media': 'http',
                'qcm': 'http',
                'assignations': {
                    type: 'http',
                    refreshEnabled: true
                },
                'assignations-group': 'http',
                'event-assignation': 'http',
                'granule': {
                    type: 'http',
                    refreshEnabled: true,
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'reference'
                    },
                    useLanguage: true
                },
                'difficulty': {
                    type: 'http',
                    cached: true,
                    useLanguage: true
                },
                'schoolyears': 'http',
                'granule-activity': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'activity'
                    }
                },
                'granule-lesson': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-sequence': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-form': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-format': {
                    type: 'http',
                    cached: true
                },
                'regions': {
                    type: 'http',
                    cached: true
                },
                'tralabar_links': {
                    type: 'http',
                    cached: true
                },
                'educational_level': {
                    type: 'http',
                    cached: true,
                    useLanguage: true
                },
                'tags': {
                    type: 'http',
                    refreshEnabled: true
                },
                'bookmarks': 'http',
                'bookmarks-theme': 'http',
                'consulted': 'http',
                'consulted_assignation': 'http',
                'evaluation': 'http',
                'corpus-text-ressource': 'http',
                'corpus-url-ressource': 'http',
                'metadatas': 'http',
                'corpus-set': {
                    type: 'http',
                    embeddings: {
                        'corpus-list': 'corpus'
                    }
                },
                'research-sheets': {
                    type: 'http',
                    refreshEnabled: true
                },
                'sections': {
                    type: 'http',
                    refreshEnabled: true
                },
                'draft-sections': {
                    type: 'http',
                    refreshEnabled: true
                },
                'section-definitions-set': {
                    type: 'http',
                    embeddings: {
                        sections: 'section-definitions'
                    }
                },
                'section-definitions': {
                    type: 'http',
                    embeddings: {
                        readingGrids: 'reading-grid'
                    }
                },
                'refresh': 'nodejs',
                'comments': 'http',
                'user-progress': 'http',
                'user-save': {
                    type: 'http',
                    embeddings: {
                        userActivity: 'user-activity'
                    }
                },
                'last-user-save': {
                    type: 'http',
                    embeddings: {
                        userActivity: 'user-activity'
                    }
                },
                'user-activity': 'http',
                'answer': 'http',
                'qcm-save': 'http',
                'ord-save': 'http',
                'app-save': 'http',
                'rb-save': 'http',
                'genericsave': 'http',
                'reading-grids': 'http',
                'draft-questions': 'http',
                'logical-links-set': 'http',
                'logical-links': 'http',
                'ideas-wall': {
                    type: 'http',
                    refreshEnabled: true
                },
                'wall-idea': {
                    type: 'http',
                    refreshEnabled: true
                },
                'wall-category': {
                    type: 'http',
                    refreshEnabled: true
                },
                'ideas_wall_category': {
                    type: 'http',
                    cached: true
                },
                'corpus_search/Granule': {
                    type: 'http',
                    refreshEnabled: true,
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'reference'
                    },
                    useLanguage: true
                },
                'basic_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'activity'
                    },
                    useLanguage: true
                },
                'assignation_search': {
                    type: 'http',
                },
                'lesson_granule_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'themes': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'theme_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'variables/instance': {
                    type: 'http',
                    useLanguage: true
                },
                'assignation_state': 'http',
                'groups_search': {
                    type: 'http'
                },
                'variables/postalCodes': {
                    type: 'http'
                },
                'chapters': {
                    type: 'http',
                    useLanguage: true
                },
                'assignation_type': {
                    type: 'http',
                    useLanguage: true
                },
                'skills': {
                    type: 'http',
                    useLanguage: true
                },
                'concepts': {
                    type: 'http',
                    cached: true,
                    useLanguage: true
                },
                'authenticated': {
                    type: 'nodejs',
                },
                'join-wall': {
                    type: 'nodejs',
                },
                'pages': {
                    type: 'http',
                    useLanguage: true
                },
                'user-points': 'http',
                'badges-type': 'http',
                'badges': {
                    type: 'http',
                    useLanguage: true
                },
                'levels': {
                    type: 'http',
                    useLanguage: true
                },
                'users-import': 'http',
                'classes-import': 'http',
                'threads': {
                    type: 'http'
                },
                'messages': {
                    type: 'http',
                    refreshEnabled: true
                },
                'error-reporting': {
                    type: 'http',
                    useLanguage: true
                },
                'events': {
                    type: 'http',
                    useLanguage: true
                },
                'analytics-listing': 'http',
                'timeline': {
                    type: 'http',
                },
                'marker_type': {
                    type: 'http',
                    cached: true,
                    useLanguage: true
                },
                'video_marker': 'http',
                'summary': 'http',
                'user-dashboard': 'http',
                'progress': 'http',
                'metrics': 'http',
                'mindmaps': 'http',
                'notepads': 'http',
                'homepage_lms': {
                    type: 'http',
                    useLanguage: true
                },
                'variables/helpVideos': 'http',
                'comments-list': {
                    type: 'http',
                    useLanguage: true
                },
                'feedbacks': 'http',
                recaptchav3: {
                    type: 'http',
                },
                'statements-analytics': 'http',
                'recommendations': 'http',
                'users-link': 'http'
            }
        }),
        LrsModule
    ],
    providers: [
        IsDefaultOrDefaultLoginRoute,
        IsUserLogged,
        IsUserAnonymous,
        ServiceInstantiator,
        TralaTranslationLoaderService,
        SvgIconList,
        CommunicationCenterService,
        DatacardService,
        EventService,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6Le-rdsUAAAAADbacQJe_sNQg1jxPsCsdqNcBvb1'
        },
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private communicationCenter: CommunicationCenterService,
        private authService: AuthenticationService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        // TODO menu
        // dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'legalnotice',
        //     'title': 'Mentions légales',
        //     'translate': 'navigation.legalnotice',
        //     'type': 'item',
        //     'icon': 'new_releases',
        //     'url': '/page/conditions-generales'
        // });
        //
        //
        // dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'privacy',
        //     'title': 'Privacy',
        //     'translate': 'navigation.privacy',
        //     'type': 'link',
        //     'icon': 'shield',
        //     'url': '/page/privacy'
        // });

        this.addHelpAnonymous();

        // TODO menu
        // router.events.pipe(filter(event => event instanceof NavigationEnd),
        //     map(() => this.activatedRoute),
        //     map(route => {
        //         while (route.firstChild) {
        //             route = route.firstChild;
        //         }
        //         return route;
        //     }),
        //     filter(route => route.outlet === 'primary'),
        //     mergeMap(route => {
        //         if (route.data) {
        //             return route.data;
        //         } else {
        //             return null;
        //         }
        //     }),)
        //     .subscribe(evt => {
        //         let menuId: string;
        //
        //         if (evt && evt['menu']) {
        //             menuId = evt['menu'];
        //         } else {
        //             menuId = 'level0';
        //         }
        //
        //         dynamicNavigation.generateMenu(menuId);
        //         dynamicNavigation.switchClassPath(menuId);
        //
        //     });
    }

    /**
     * Replace help link with appropriate url
     */
    private updateHelpLink(Urlsuffix = ''): void {
        // TODO menu
        // this.translate.get('navigation.needhelp_url' + Urlsuffix).subscribe((url: string) => {
        //     this.dynamicNavigation.registerModuleMenu('level0', {
        //         'id': 'needhelp',
        //         'title': 'Need help',
        //         'translate': 'navigation.needhelp',
        //         'type': 'link',
        //         'icon': 'help',
        //         'url': url
        //     });
        // });
    }

    private postAuthentication(): void {
        // TODO menu
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'needhelp',
        //     'title': 'Need help',
        //     'translate': 'navigation.needhelp',
        //     'type': 'link',
        //     'icon': 'help',
        //     'url': '/'
        // });
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //         'id': 'home',
        //         'title': 'Home',
        //         'translate': 'navigation.home',
        //         'type': 'item',
        //         'icon': 'home',
        //         'url': '/home'
        //     }
        // );
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'funambuleMove',
        //     'title': 'Manoeuvre du funambule',
        //     'translate': 'funambule.move',
        //     'type': 'item',
        //     'icon': 'funambule',
        //     'url': '/page/manoeuvre-funambule'
        // });
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'virtualClass',
        //     'title': 'Classe virtuelle',
        //     'translate': 'virtual.class',
        //     'type': 'item',
        //     'icon': 'virtual_class',
        //     'url': '/page/classe-virtuelle'
        // });
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'needhelp',
        //     'title': 'Need help',
        //     'translate': 'navigation.needhelp',
        //     'type': 'link',
        //     'icon': 'help',
        //     'url': '/'
        // });
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'mentions-legales',
        //     'title': 'mentions-legales',
        //     'translate': 'navigation.legalnotice',
        //     'type': 'item',
        //     'icon': 'visibility_legalnotice',
        //     'url': '/page/mentions-legales'
        // });
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'cgurgpd',
        //     'title': 'cgurgpd',
        //     'translate': 'navigation.cgurgpd',
        //     'type': 'item',
        //     'icon': 'visibility_cgurgpd',
        //     'url': '/page/cgurgpd'
        // });
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'politique-confidentialite',
        //     'title': 'politique-confidentialite',
        //     'translate': 'navigation.privacy',
        //     'type': 'item',
        //     'icon': 'visibility_privacy',
        //     'url': '/page/politique-confidentialite'
        // });

    }

    private postLogout(): void {
        this.addHelpAnonymous();
    }


    /**
     * Add link to helper resources just for unlogged user
     * @private
     */
    private addHelpAnonymous(): void {
        // TODO menu
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'help_anonymous',
        //     'title': 'help',
        //     'translate': 'navigation.general_help',
        //     'type': 'item',
        //     'icon': 'help',
        //     'url': '/page/aide-aux-ressources'
        // });
    }
}
