import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {brand, defaultApiURL} from './settings';

export interface Locale {
    lang: string;
    data: Object;
}

@Injectable()
export class TralaTranslationLoaderService {
    public brand: string = brand;

    constructor(
        private translate: TranslateService,
        private http: HttpClient

    ) {
    }

    public instanceCustomTranslation(data, instance: string){
        // console.log('data instance = ', data);
        // console.log('instance name = ', instance);
        Object.entries(data).forEach(([key, value]) => {
            // console.log(`key= ${key} value = ${value}`);
            // if($key === $fxinstance && is_array($entry) ){
            if ( key === instance  ){
                // console.log('CUSTOM KEYS');
                Object.entries(value).forEach(([key2, value2]) => {
                    // console.log(`key2= ${key2} value2 = ${value2}`);
                    // console.log('toto = ', key2.split('.') );
                    const arrFullKey = key2.split('.');
                    const changeKey = key2.substr( arrFullKey[0].length + 1, key2.length );
                    // console.log('changeKey = ', changeKey);
                    // console.log('data origine value = ', data[arrFullKey[0]][ changeKey ]);
                    data[arrFullKey[0]][ changeKey ] = value2;
                });
            }
        });
    }

    public loadTranslations(...args: string[]): void {
        const locales = [...args];

        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this.http.get(defaultApiURL + 'i18n/' + locale + '.json').subscribe((data) => {
                this.instanceCustomTranslation(data, this.brand);
                this.translate.setTranslation(locale, data, true);
            });
        });
    }
}



