import {Component, OnInit} from '@angular/core';
import {AssignationService} from '../../assignation.service';


@Component({
    selector: 'app-followed-tabs',
    templateUrl: './followed-tabs.component.html',
    styleUrls: ['./followed-tabs.component.scss']
})

/**
 * component use to wrapp diffrent followed component into tabs
 */
export class FollowedTabsComponent implements OnInit {
    public selectedTab = 0;

    constructor(
        private assignationService: AssignationService,
    ) {}

    public get translationTabLabelList(): string {
        return this.assignationService.translationTabLabelListByRole();
    }
    public get translationTabLabelLogBook(): string {
        return this.assignationService.translationTabLabelLogBookByRole();
    }

    ngOnInit(): void {
    }
}
