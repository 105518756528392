import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {DataEntity, OctopusConnectService} from "octopus-connect";
import {FormData, FormQuestion} from "fuse-core/components/form-dialog/form.model";
import {Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";
import {FormDialogComponent} from "fuse-core/components/form-dialog/form-dialog.component";
import {CommunicationCenterService} from "@modules/communication-center";

@Injectable({
    providedIn: 'root'
})
export class FormDialogService {
    private currentForm: DataEntity | null = null;
    
    constructor(
        private dialog: MatDialog, 
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService,
    ) {
        this.communicationCenter
            .getRoom('lms')
            .next('formDialog', FormDialogComponent);
    }
    
    public openForm(surveyLink?: DataEntity): void {
        this.currentForm = surveyLink ?? null;
        this.dialog.open(FormDialogComponent, {
            disableClose: true,
        });
    }
    
    public loadForm$(): Observable<FormQuestion[]> {
        let form$: Observable<FormQuestion[]>;
        
        if (this.currentForm) {
            form$ = of(this.currentForm.get('questions'));
        } else {
            form$ = this.octopusConnect
                .loadCollection('survey-config')
                .pipe(
                    take(1),
                    map((surveyConfig) => {
                        return surveyConfig.entities.map((question) => {
                            return {
                                id: question.id.toString(),
                                type: question.get('type'),
                                key: question.get('key'),
                                question: question.get('question'),
                                options: question.get('options'),
                                required: question.get('required'),
                            } as FormQuestion;
                        });
                    }),
                );
        }
        
        return form$;
    }
    
    public loadSubmissions(): Observable<DataEntity[]> {
        return this.octopusConnect
            .loadCollection('survey')
            .pipe(
                take(1),
                map((survey) => {
                    return survey.entities;
                }),
            )
    }
    
    public submitForm(formData: FormData): Observable<DataEntity> {
        if (this.currentForm) {
            const formSave = this.octopusConnect.createEntity('survey-save', {
                survey: this.currentForm.id,
                answers: JSON.stringify(formData),
            })
            this.currentForm = null;
            return formSave;
        } else {
            return this.octopusConnect.createEntity('survey', formData);
        }
    }
}
