import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {modulesSettings} from '../settings';
import {ModelSchema, Structures} from 'octopus-model';
import * as _ from 'lodash-es';
import {AuthorizationService} from '@modules/authorization';
import {combineLatest, Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {DataCardInterface} from 'fuse-core/components/card/data-card.interface';

const AUTONOMY_USAGE_LABEL = 'usage.autonomie';
const COLLECTIVE_USAGE_LABEL = 'usage.collectif';

const settingsStructure: ModelSchema = new ModelSchema({
    cardLayout: Structures.string('card-simple-course'),
    progress: Structures.boolean(),
    grade: Structures.boolean(),
    isLessonPreviewAccessible: Structures.boolean(false),
    isPlayLessonActionIsLimitedToCollectiveAssignment: Structures.boolean(false),
});

@Injectable({
    providedIn: 'root'
})
export class DatacardService {

    userData: DataEntity;
    public settings: { [key: string]: any };
    private allowedRolesForModelsAssignation: string[] = [];
    private isAssignationFeatureActive: boolean;
    private isAssignationIsLimitedByUsage: boolean;

    constructor(
        public octopusConnect: OctopusConnectService,
        public http: HttpClient,
        public accountManagementProvider: AccountManagementProviderService,
        public authenticationService: AuthenticationService,
        public authorizationService: AuthorizationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                } else {
                    this.userData = null;
                }

            });

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('allowedRolesForModelsAssignation')
            .subscribe((data: string[]) => {
                this.allowedRolesForModelsAssignation = data ? data : [];
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('isAssignationActionIsActive')
            .subscribe((data: boolean) =>
                this.isAssignationFeatureActive = data
            );


        this.communicationCenter
            .getRoom('assignation')
            .getSubject('isAssignationIsLimitedByUsage')
            .subscribe((data: boolean) => {
                this.isAssignationIsLimitedByUsage = data;
            });

        this.settings = settingsStructure.filterModel(modulesSettings.activities);
    }

    public checkAccess(user: string[]): boolean {
        if (user) {
            return this.authenticationService.hasLevel(user);
        }
        return false;
    }

    public isDuplicable(): boolean {
        // TODO pas en dur
        return this.checkAccess(['trainer', 'administrator', 'manager']);
    }

    public isByRole(type): boolean {
        return type === 'byRole';
    }

    public isManager(): boolean {
        return this.checkAccess(['manager']);
    }


    /**
     * Return true if type (model or not) of page and current user roles allow assignments
     * @param type if it's a model, value is 'byRole'
     * todo, c'est pas a ce service de gérer ça. C'est au parent de fournir les infos quand il passe les ressources.
     * todo le parent devrait appeler le service d'authorization qui sera en charge de faire ces tests
     */
    public isAssignable$(type, resource): Observable<boolean> {
        let assignationAllowed = false;

        if (this.isByRole(type)) {
            assignationAllowed = this.allowedRolesForModelsAssignation.includes(this.authenticationService.accessLevel);
        } else {
            assignationAllowed = this.checkAccess(['trainer', 'administrator']);
        }

        const allowedByUsages = this.isAssignmentAllowedByLessonUsage(resource, AUTONOMY_USAGE_LABEL);

        const currentUserCanAssign = this.authorizationService.currentUserCan<Observable<boolean>>('assignment.can-assign-lessons', undefined, resource);

        return combineLatest([
            currentUserCanAssign,
            of(allowedByUsages),
            of(assignationAllowed)
        ]).pipe(map(allMustBeTrue => allMustBeTrue.every(mustBeTrue => mustBeTrue === true)));
    }

    public processResources<T extends DataEntity>(resources: any[], callbacks: Partial<DataCardInterface<T>>, type, userSaves?: DataEntity[], options?: {[key: string]: any}): DataCardInterface<T>[] {
        return resources.map((resource) => {
            // inutile...
            const originalResource = resource;

            if (resource.ressourceEntity !== undefined) {
                resource = resource.ressourceEntity;
            }

            let dataCard: Partial<DataCardInterface<T>> = {
                'resource': resource,
                'originalResource': originalResource,
                'displayAssignedCount': !this.isByRole(type),
                'displayDuplicationCount': this.isByRole(type),
                'displayLesson': (_data) => false, // TODO a vérifier mais semble n'etre jamais a true
                'displayTheme': (_data) => false, // TODO a vérifier mais semble n'etre jamais a true
                'isAssignable$': this.isAssignable$(type, resource).pipe(filter(isAssignable => isAssignable && this.isAssignationFeatureActive)),
                'isDuplicable$': (_data) => of(this.isDuplicable()),
                isPreviewAccessible: this.settings.isLessonPreviewAccessible,
                isPlayAccessible: this.isUsageAuthorizeToPlayLesson(resource),
                isQuizAccessible: options?.displayQuizBtnInCard,
                isQuizEnable: this.isQuizAccessible(resource, userSaves) && options?.displayQuizBtnInCard,
            };

            dataCard = _.merge(
                dataCard,
                callbacks,
                {
                    'playAlt': callbacks.play,
                    'download': callbacks.play
                }
            );

            return dataCard as DataCardInterface<T>;
        });
    }

    public isQuizAccessible(resource: DataEntity, userSaves: DataEntity[]): boolean {
        const isActivityDone = userSaves && userSaves.find((userSave) =>
            userSave.get('granuleParent') === resource.id
            && resource.get('reference')[0].id === userSave.get('granule')
            && userSave.get('state') === 'validated');
        return !!isActivityDone;
    }

    /***
     * download document and open in a new tab
     * @param path : path of the document
     */
    public downloadDoc(path: string): void {
        window.open(path, '_blank');
    }

    private isAssignmentAllowedByLessonUsage(resource: DataEntity, usage: string): boolean {
        let allowedByUsages = true;
        if (this.isAssignationIsLimitedByUsage) {
            allowedByUsages = this.checkLessonUsageType(resource, usage);
        }

        return allowedByUsages;
    }

    private checkLessonUsageType(resource: DataEntity, usage: string): boolean {
        if (usage !== AUTONOMY_USAGE_LABEL && usage !== COLLECTIVE_USAGE_LABEL) {
            throw new Error('Type must be "en autonomie" or "collectif"');
        }

        const usages: {label: string}[] = _.get(resource, 'attributes.usage', []);
        return usages.length > 0 && usages.some(u => u.label === usage);
    }

    private isUsageAuthorizeToPlayLesson(resource: DataEntity): boolean {
        const isPlayLessonIsLimitedToCollectiveAssignment = this.settings.isPlayLessonActionIsLimitedToCollectiveAssignment;
        const isACollectiveAssignment = this.checkLessonUsageType(resource, COLLECTIVE_USAGE_LABEL);
        return (isPlayLessonIsLimitedToCollectiveAssignment && isACollectiveAssignment) || !isPlayLessonIsLimitedToCollectiveAssignment;
    }
}
