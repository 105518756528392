import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CollectionOptionsInterface} from 'octopus-connect';
import {UntypedFormControl} from '@angular/forms';
import * as _ from 'lodash';
import {FieldListOption} from './field-list.option';
import {FieldTextOption} from './field-text.option';
import {tap} from 'rxjs/operators';

export type allowedFieldType = FieldTextOption | FieldListOption;

@Component({
    selector: 'app-generic-search-filters',
    templateUrl: './generic-search-filters.component.html'
})
export class GenericSearchFiltersComponent implements OnChanges {
    @Output() execute: EventEmitter<CollectionOptionsInterface> = new EventEmitter<CollectionOptionsInterface>();
    @Output() info: EventEmitter<string> = new EventEmitter<string>();
    public controls: { field: allowedFieldType, formControl: UntypedFormControl }[] = [];
    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 12,
        orderOptions: []
    };

    @Input() fields: allowedFieldType[] = [];

    public onSearchButtonClick(): void {
        this.updateFilter();
        this.execute.emit(this.optionsInterface);
    }

    /**
     * info button is click send the id to parent
     * @param id
     */
    public infoClick(id: string): void {
        this.info.emit(id);
    }

    public isFieldTextOption(field: allowedFieldType): boolean {
        return this.checkType(field, 'FieldTextOption');
    }

    public isFieldListOption(field: allowedFieldType): boolean {
        return this.checkType(field, 'FieldListOption');
    }

    private updateFilter(): void {
        this.optionsInterface.urlExtension = '';

        for (const control of this.controls) {
            if ([undefined, null, false, 'all', 'allf', ''].includes(control.formControl.value) || control.field.hidden) {
                delete this.optionsInterface.filter[control.field.endpoint];
            } else {
                if (_.get(control.field, '.isUrlExtension', false)) {
                    this.optionsInterface.urlExtension = control.formControl.value;
                } else {
                    this.optionsInterface.filter[control.field.endpoint] = control.formControl.value;
                }
            }
        }
    }

    private checkType(field: allowedFieldType, type: string): boolean {
        return field.type === type;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.controls = this.fields.map(field => {
            field.endpoint = (!!field.endpoint) ? field.endpoint : field.id;
            // if there's a value previously selected we push it again if data change in replace of default value
            const control = new UntypedFormControl((!!field.selectedValue ? field.selectedValue : field.defaultValue));

            if (field.disabled) {
                control.disable();
            }
            if (!!field.onChange) {
                control.valueChanges.pipe(tap(value => field.onChange.next(value))).subscribe();
            }

            return {
                field,
                formControl: control
            };
        });
    }
}


