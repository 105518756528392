import {DataEntity} from 'octopus-connect';
import {GamificationService} from './../gamification.service';
import {Component, NgZone, OnInit, OnDestroy, Input} from '@angular/core';
import {SpinePlayer, Skin, Animation} from '@esotericsoftware/spine-player';
import {PlayerConfig} from './spine-player-config';
import {Subscription} from 'rxjs';


@Component({
    selector: 'app-animated-canvas',
    templateUrl: './animated-canvas.component.html',
    styleUrls: ['./animated-canvas.component.scss']
})
export class AnimatedCanvasComponent implements OnInit, OnDestroy {
    public idPlayerContainer = 'player-container'; // never delete this it's use in AnimatedCanvasInMyProgressComponent to define another id
    private spinePlayer: SpinePlayer;
    private skinsSubscription: Subscription;
    private animationSubscription: Subscription;
    private playerLoaded = false;
    public noDance = false;
    availableSkins: Skin[];
    availableAnimations: Animation[];

    @Input() public previewBadge?: DataEntity;
    // TODO: charger les selected skin par rapport au user courrant/badges etc.
    selectedDataSkins: DataEntity[];
    selectedSkins: Skin[];
    selectedAnimationName = '';

    constructor(protected zone: NgZone, public gamificationService: GamificationService) {
    }

    ngOnDestroy(): void {
        this.spinePlayer.stopRendering();
        this.skinsSubscription.unsubscribe();
        this.animationSubscription.unsubscribe();
    }

    // Callback when spinePlayer lib will end all assets loading and parsing
    private avatarAssetsLoaded(player: SpinePlayer): void {
        // This function will be called by the spinePlayer lib, need to update the angular so this needs to be run in angular zone (update the angular UI)
        this.zone.run(() => {
            this.playerLoaded = true;
            this.availableSkins = player.skeleton.data.skins;
            this.availableAnimations = player.skeleton.data.animations;
            this.resetAvatar(this.selectedDataSkins);
        });

    }

    public setAnimation(name: string): void {
        // use to force no dance and overide default value because user have win no star
        // data pass by [params] in app-dynamic component
        if (this.noDance) {
            name = this.gamificationService.settings.defaultAnimationName;
        }
        const animation = this.availableAnimations.find((element) => {
            return element.name === name;
        });
        if (animation) {
            this.spinePlayer.setAnimation(animation, true);
            this.spinePlayer.play();
        }
    }

    // Will update the current avatar display
    public setSkin(): void {
        const currentSkin = new Skin('displayed');

        for (const skin of this.selectedSkins) {
            currentSkin.addSkin(skin);
        }
        this.zone.runOutsideAngular(() => {
            this.spinePlayer.skeleton.setSkin(currentSkin);
            this.spinePlayer.skeleton.setToSetupPose();
            this.spinePlayer.skeleton.updateWorldTransform();
        });
    }

    public addDebugSkin(skin: Skin): void {
        this.selectedSkins.push(skin);
        this.setSkin();
    }

    // When an item is clicked in the Ui, will add or remove the skin asset from the player Avatar
    public removeSkin(skin: Skin): void {
        const index = this.selectedSkins.findIndex((equiped) => {
            return equiped.name === skin.name;
        });
        if (index >= 0) {
            this.selectedSkins.splice(index, 1);
            this.setSkin();
        }
    }

    public isSkinEquiped(skin: Skin): boolean {
        let result = true;
        if (!this.selectedSkins.find((equiped) => {
            return equiped.name === skin.name;
        })) {
            result = false;
        }
        return result;
    }

    private findSkinByName(name: string): Skin | void { // Should return the skin
        return this.availableSkins.find(skin => {
            return skin.name === name;
        });
    }

    private resetAvatar(datas: DataEntity[]): void {
        for (const data of datas) {
            if (this.gamificationService.isAnimation(data)) {
                if (data.attributes['selected']) {
                    this.setAnimation(data.attributes['label']);
                }
            } else {
                const skin = this.findSkinByName(data.attributes.label);
                if (skin && data.attributes['selected'] && !this.isSkinEquiped(skin)) {
                    this.selectedSkins.push(skin);
                } else if (skin && !data.attributes['selected']) { // or locked ? 
                    this.removeSkin(skin);
                }
            }
        }
        if (this.previewBadge) {
            if (this.gamificationService.isAnimation(this.previewBadge)) {
                this.setAnimation(this.previewBadge.attributes['label']);
            } else {
                // il faut désactiver le skin actif pour cette catégorie et le remplacer par le skin de la preview
                const currentSelectedSkin = datas.filter((elem) => {
                    return elem.attributes['type']['id'] === this.previewBadge.attributes['type']['id'] && elem.attributes['selected'];
                })[0];
                if (currentSelectedSkin) {
                    const skinToBeRemoved = this.findSkinByName(currentSelectedSkin.attributes['label']);
                    if (skinToBeRemoved) {
                        this.removeSkin(skinToBeRemoved);
                    }
                }
                const skinToBeAdded = this.findSkinByName(this.previewBadge.attributes['label']);
                if (skinToBeAdded) {
                    this.selectedSkins.push(skinToBeAdded);
                }
            }
        }
        this.setSkin();
    }

    ngOnInit(): void {
        this.availableSkins = [];
        this.selectedSkins = [];
        const playerConfig = new PlayerConfig();
        playerConfig.jsonUrl = this.gamificationService.settings.skeletonJson;
        playerConfig.atlasUrl = this.gamificationService.settings.skeletonAtlas;
        playerConfig.showControls = false;
        playerConfig.alpha = true;
        playerConfig.animation = this.gamificationService.settings.defaultAnimationName;
        playerConfig.showLoading = false;
        playerConfig.success = this.avatarAssetsLoaded.bind(this);
        playerConfig.viewport = {
            x: 0,
            y: 0,
            width: 625,
            height: 625,
            padLeft: '100%',
            padTop: '15%',
            padBottom: null,
            padRight: null,
            debugRender: false,
            transitionTime: null, 
            animations: null
        };
        this.selectedDataSkins = this.gamificationService.skins;
        this.skinsSubscription = this.gamificationService.skinsObservable.subscribe((datas: DataEntity[]) => {
            if (this.playerLoaded) {
                this.gamificationService.avatarWasLoadOnce = true;
                this.resetAvatar(datas);
            }
        });

        this.animationSubscription = this.gamificationService.animationObservable.subscribe((animationName) => {
            if (this.playerLoaded) {
                this.setAnimation(animationName);
            }
        });

        this.zone.runOutsideAngular(() => {
            /// Watever happens in this block will be ignored by angular.
            /// especialy animations triggers that will kill if the library changes the angular state on requestAnimationFrame
            this.spinePlayer = new SpinePlayer(this.idPlayerContainer, playerConfig);
        });
    }

}
