import {TypedDataCollectionInterface, TypedDataEntityInterface} from './octopus-connect';

export type ConceptAttributes = {
    name: string;
    label: string;
    // Les ID des educational levels
    levels: string[] | null;
    showIn: string[] | null;
    id: string;
    image: {
        uri: string;
    }
};

export type ConceptEntity = TypedDataEntityInterface<ConceptAttributes>;
export type ConceptDataCollection = TypedDataCollectionInterface<ConceptAttributes>;