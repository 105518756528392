import {Directive, ElementRef, Input, OnInit} from '@angular/core';
/**
 * Je propose de supprimer ça :
 * - on manipule manuellement le style, ce qui n'est pas une bonne pratique
 * - on a deja une dépendance du avec le css,
 * - on multiplie la dépendance de l'application sur cette directive alors qu'un comportement natif d'angular permet de s'en passer pour plus simple
 *
 * Le comportement d'angular : On peut avec un "ngfor" boucler sur une propriété du settings, chaque clé cette propriété est un id de widget et sa config.
 * Donc chaque boucle, on appelle un component factory en lui passant ladite clé et son setting.
 *
 * La majorité des widget sont déjà prêts, ils utilisent la génération de component dynamique
 * Pour les filtres, on a déjà évoqué qu'il fallait y faire une refacto
 */   
@Directive({
    selector: '[appMoveElement]'
})
/**
 * directive for moving position of html element in regard of settings
 * take an array of fields, the current fields and a boolean to apply or not moving html element.
 * the position of the fields will be the same position as in the array
 *
 * @remarks /!\Important: Need to use flex to work because use the order flex style to move html element./!\
 */
export class MoveElementDirective implements OnInit {

    @Input() movableElementFields: string[]; // list of fields ordered
    @Input() movableElementField: string; // current field to move at the good place in regard of fields
    @Input() movableElementIsActive = false; // active or not the directive

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        if (this.movableElementIsActive) {
            this.el.nativeElement.setAttribute('style', 'order: ' + this.movableElementFields.indexOf(this.movableElementField) + 1);
        }
    }
}
