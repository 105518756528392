import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {MainMenuOpeningModeEnum} from '../models/main-menu-opening-mode.enum';
import {modulesSettings} from '../../settings';
import {IExternalLinkWithLicense, MainMenuSettingsInterface} from '../models/main-menu-settings.interface';
import * as _ from 'lodash';
import {MainMenuElementGenericBase} from '../models/main-menu-element-generic.base';
import { AccessLevel } from 'app/shared/models/access-level.type';

const settingsStructure: ModelSchema = new ModelSchema(({
    menu: Structures.object({default: {}}),
    sideNavMode: Structures.string(MainMenuOpeningModeEnum.side),
    isOpenedByDefault: Structures.boolean(true),
    externalLinkWithLicense: Structures.object()
}));

@Injectable({
    providedIn: 'root'
})
export class DynamicMenuSettingsService {
    private settings: MainMenuSettingsInterface;

    constructor() {
        this.settings = settingsStructure.filterModel(modulesSettings.mainMenu) as MainMenuSettingsInterface;
    }

    public getDefaultMode(): MainMenuOpeningModeEnum {
        return this.settings.sideNavMode;
    }

    public getDefaultOpenedState(): boolean {
        return this.settings.isOpenedByDefault;
    }

    public getExternalLinkWithLicense(): IExternalLinkWithLicense {
        return this.settings.externalLinkWithLicense;
    }

    public getMenuForRole(accessLevel: AccessLevel, fallback: AccessLevel | 'default' = 'default'): MainMenuElementGenericBase[] {
        if (_.has(this.settings.menu, accessLevel)) {
            return this.settings.menu[accessLevel];
        } else if (_.has(this.settings.menu, 'default')) {
            return this.settings.menu.default;
        }

        throw new Error(`Cannot find menu in settings for role "${accessLevel}" or for fallback role ${fallback}`);
    }
}
