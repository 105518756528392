import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {DataEntity} from 'octopus-connect';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {isTypeOf} from "shared/utils";

@Component({
    selector: 'show-profile',
    templateUrl: './show-profile.component.html',
    styleUrls: ['./show-profile.component.scss'],
})
export class ShowProfileComponent implements OnInit {

    userInformation: DataEntity | {};
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(public profileProvider: ProfileService,
                private dialog: MatDialog,
                private translate: TranslateService,
                private router: Router) {

    }

    ngOnInit() {
        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {
            this.userInformation = userInformation;

            if (isTypeOf<DataEntity>(this.userInformation, "get") && this.userInformation.get('sso') && this.userInformation.get('first_access') === true) {
                this.help();
            }
        });

    }

    private help(): void {
        const data = {
            titleDialog: 'generic.ask.help',
            bodyDialog: 'generic.ask.help.content',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        };

        this.translate.get(data.titleDialog).subscribe((translation: string) => data.titleDialog = translation);
        this.translate.get(data.bodyDialog).subscribe((translation: string) => data.bodyDialog = translation);
        this.translate.get(data.labelTrueDialog).subscribe((translation: string) => data.labelTrueDialog = translation);
        this.translate.get(data.labelFalseDialog).subscribe((translation: string) => data.labelFalseDialog = translation);

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (isTypeOf<DataEntity>(this.userInformation, "set")) {


                this.userInformation.set('first_access', 0);
                this.userInformation.save();
                if (result) {
                    // help page
                    this.router.navigate(['home']);
                }
                this.confirmDialogRef = null;
            }
        });
    }

    public getMode(): boolean {
        return this.profileProvider.selectedMode;
    }
}
